// // AccountActivation.jsx
// import React, { useEffect } from 'react';
// import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
// import useActivation from './useActivation';

// function AccountActivation(props) {
//     const { token } = useParams();
//     const [searchParams, setSearchParams] = useSearchParams();
//     const email = searchParams.get('email');
//     const navigate = useNavigate();
//     const { activateAccount } = useActivation();

//     // console.log(email, token);

//     useEffect(() => {
//         activateAccount(token, email);
//         setTimeout(()=>{
//             navigate('/dashboard'); // Redirect to dashboard
//         },5000)

//     }, [token, email]); // Ensure activation runs when token or email changes

//     return (
//         <div>
//             {/* While activation is in progress, you might want a loading spinner here */}
//         </div>
//     );
// }

// export default AccountActivation;

// AccountActivation.jsx
// import React, { useEffect } from 'react';
// import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import useActivation from './useActivation';

// import { activateAccount } from '../../actions/authenticationActions'; // Adjust the import according to your file structure

// const AccountActivation =()=>{
//     const { token } = useParams();
//     const dispatch = useDispatch();
//     const [searchParams, setSearchParams] = useSearchParams();
//     const email = searchParams.get('email');
//     const navigate = useNavigate();
//     //const { activateAccount } = useActivation();

//     // console.log(email, token);

//     useEffect(() => {
//         const sendUserData = async () => {
//           try {
//             const redirect = "/dashboard";
//             // console.log(email, token,"hey there");
//             dispatch(activateAccount(token, email, navigate, { redirect }));
//           } catch (error) {
//             console.error('Error fetching user data:', error);
//             // Handle error, e.g., show notification, redirect to an error page, etc.
//           }
//         };

//         sendUserData();
//       }, [dispatch, navigate]);

//     return (
//         <div>
//             {/* While activation is in progress, you might want a loading spinner here */}
//         </div>
//     );
// }

// export default AccountActivation;

import React, { useEffect, useState } from "react";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners"; // Example spinner component
import { activateAccount } from "../../actions/authenticationActions"; // Adjust the import according to your file structure

const AccountActivation = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  // console.log(email, token);

  useEffect(() => {
    const sendUserData = () => {
      try {
        const redirect = "/dashboard";
        // console.log(email, token, "hey there");
        dispatch(activateAccount(token, email, navigate, { redirect }));
      } catch (error) {
        console.error("Error fetching user data:", error);
        // Handle error, e.g., show notification, redirect to an error page, etc.
      }
    };

    sendUserData();
  }, [dispatch, navigate, token, email]);

  return (
    <div>
      {loading ? (
        <div style={styles.loaderContainer}>
          <ClipLoader color={"#123abc"} loading={loading} size={50} />
          <p style={styles.loadingText}>
            Activating your account, please wait...
          </p>
        </div>
      ) : (
        <p style={styles.successText}>
          Your account has been activated! Redirecting to dashboard...
        </p>
      )}
    </div>
  );
};

const styles = {
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh", // Full height of the viewport
    textAlign: "center",
  },
  loadingText: {
    marginTop: "20px",
    fontSize: "16px",
  },
  successText: {
    textAlign: "center",
    fontSize: "16px",
  },
};

export default AccountActivation;
