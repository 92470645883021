// import PropTypes from "prop-types";
// import React from "react";
// import _ from "lodash";
// import { createBrowserHistory } from 'history';

// import moment from "moment-timezone";

// import { Header, Table, Tab, Popup, Icon, Dropdown, Input, Pagination } from "semantic-ui-react";
// import CreateTimerButtonContainer from "../../containers/createTimerButtonContainer";
// import Clipboard from "react-clipboard.js";
// import UpgradePlanButton from "../billing/upgradePlanButton";

// import timerHelper from "../../helpers/timers/timerHelper";
// import TimerPreview from "../shared/timerPreview";

// import css from "../../assets/styles/timerList/index.module.scss";
// import tableCss from "../../assets/styles/shared/tables.module.scss";

// import gridCss from "../../assets/styles/shared/grids.module.scss";

// import { DeleteIconComponent, EditIconComponent, LeftArrowIconComponent, RightArrowIconComponent } from "../iconComponents";
// import CreateTimerButton from "./createTimerButton";

// const browserHistory = createBrowserHistory();

// const { object, array } = PropTypes;

// class TimerList extends React.Component {

//   state = {};

//   static propTypes = {
//     timers: object,
//     templates: array,
//     envContext: object,
//     user: object,
//     subaccounts:array
//   };

//   constructor(props) {
//     super(props);

//     const timezone = moment.tz.guess();
//     const now = moment().tz(timezone);
//     const nearestHour =
//       now.minute() || now.second() || now.millisecond() ? now.add(1, "hour").startOf("hour") : now.startOf("hour");

//     this.state = {
//       selectedTemplateId: "",
//       timerName: "",
//       dateTime: nearestHour,
//       trialCreate: true,
//       subaccounts:[{key: "drop_subaccount_0",text: "All",value: -1},{key: "drop_subaccount_00",text: "Corporate Timers",value: 0}],
//       current_subaccount_id:-1,
//       totalTimer:0,
//       searchText:"",
//       recordLimit:10,
//       timerActivePage:1,
//       sortOptions:[
//         { key: 111001,text:'Creation date',value:'created_at',sort:'desc' },
//         { key: 111002,text:'End date',value:'ends_at',sort:'desc' },
//         { key: 111003,text:'Total views',value:'total_views',sort:'desc' },
//         { key: 111004,text:'Views (this billing cycle)',value:'paid_views',sort:'desc' },
//       ],
//       currentSort:{ key: 111001,text:'Creation date',value:'created_at',sort:'desc' },
//     };
//   }

//   isCorporateUser() {
//     return this.props.user.is_company || (this.props.user.features.subaccount && this.props.user.parent_id == 0);
//   }

//   isSubaccountUser() {
//     return this.props.user.subaccount_id != 0;
//   }

//   componentDidMount() {
//     this.props.fetch();
//     if(this.isCorporateUser()){
//       this.props.fetchSubaccounts();
//     }
//   }

//   static getDerivedStateFromProps(props,current_state){
//     if(props.subaccounts && props.subaccounts.length  > 0){
//       let newStates = {...current_state};
//       if(newStates.subaccounts.length != props.subaccounts.length){
//         const subaccountOptions = _.map(props.subaccounts, (subaccount, index) => ({
//           key: "drop_subaccount_" + subaccount.id,
//           text: subaccount.name,
//           value: subaccount.id,
//         }));
//         subaccountOptions.unshift({key: "drop_subaccount_0",text: "All",value: -1},{key: "drop_subaccount_00",text: "Corporate Timers",value: 0});
//         newStates.subaccounts = [...subaccountOptions];
//         return newStates;
//       }
//     }
//     return null;
//   }

//   componentDidUpdate(prevProps) {
//     // User's plan changed, refetch all timers
//     if (prevProps.user.tier != this.props.user.tier) {
//       this.props.fetch();
//     }
//   }

//   setTrialCreate(value) {
//     // Allow creating timers in trial
//     this.setState({
//       trialCreate: value == 1,
//     });
//   }

//   // formatDate = (date) => {
//   //   // return date.format("MMMM Do YYYY, h:mma z");
//   //   return date.format("MMM DD, YYYY HH:mm");
//   // };

//   formatDate = (date) => {
//     return moment(date).format("MMM DD, YYYY hh:mm A");
//   };

//   format24HoursDate = (date) => {
//     return moment(date).format("MMM DD, YYYY HH:mm");
//   };

//   handleArchive = (timerId, archived) => {
//     return (e) => {
//       e.stopPropagation();
//       e.preventDefault();
//       this.props.archive(timerId, !archived);
//     };
//   };

//   handleEdit = (timerId) => {
//     // console.log("timerId",timerId)
//     return () => {
//       browserHistory.push("/timer/" + timerId);
//     };
//   };

//   handleCreateTimer = (options) => {
//     this.props.create(options);
//   };

//   validDates = (current) => {
//     const yesterday = moment().subtract(1, "day");
//     return current.isAfter(yesterday);
//   };

//   renderEditIcon = (archived, timer) => {
//     const { user } = this.props;

//     if (archived) {
//       return null;
//     }

//     return (
//       <Popup
//         inverted
//         // trigger={<Icon name="edit" onClick={this.handleEdit(timer.id)} />}
//         trigger={<span style={{cursor:'pointer'}} onClick={this.handleEdit(timer.timer_id)}><EditIconComponent/></span>}
//         content={"Edit"}
//         position={"top center"}
//       />
//     );
//   };

//   renderArchiveIcon = (archived, id) => {
//     const info = archived ? "Unarchive" : "Archive";

//     return (
//       <Popup
//         inverted
//         // trigger={<Icon name="archive" onClick={this.handleArchive(id, archived)} />}
//         trigger={<span style={{cursor:'pointer'}} name="trash" onClick={this.handleArchive(id, archived)}><DeleteIconComponent/></span>}
//         content={info}
//         position={"top center"}
//       />
//     );
//   };

//   renderCopyLink(url) {
//     if (url) {
//       const copyButton = (
//         <Clipboard component="span" data-clipboard-text={url}>
//           <Icon name="linkify" className={tableCss.linkIcon} />
//         </Clipboard>
//       );

//       return <Popup inverted trigger={copyButton} content="Copied!" position="top center" hideOnScroll />;
//     } else {
//       return (
//         <Popup
//           inverted
//           content="Upgrade your plan to use this timer"
//           position="top center"
//           hideOnScroll
//           trigger={<Icon name="linkify" disabled />}
//         />
//       );
//     }
//   }

//   handleTimerPaginationChange = (e, { activePage }) => this.setState({ timerActivePage:activePage })

//   renderZeroState = (status) => {
//     if (status === "active") {
//       return (
//         <div className={tableCss.zero}>
//           You do not currently have any active timers. Create one with the button in the top right!
//         </div>
//       );
//     } else {
//       return <div className={tableCss.zero}>You do not currently have any archived timers.</div>;
//     }
//   };

//   // renderEndDate = (timer) => {
//   //   if (timer.timerType === "standard") {
//   //     const endsAt = moment(timer.endsAt).tz(timer.timeZone);
//   //     return this.formatDate(endsAt);
//   //   } else if (timer.timerType === "perpetual") {
//   //     const durationObject = timerHelper.getDurationObject(timer.duration);
//   //     const { days, hours, minutes, seconds } = durationObject;
//   //     return `Perpetual (${days}d ${hours}h ${minutes}m)`;
//   //   } else if (timer.timerType === "dynamic") {
//   //     return "(Dynamic Timer)";
//   //   } else {
//   //     return null;
//   //   }
//   // };

//   renderEndDate = (timer) => {
//     if (timer.timer_type === "standard") {
//       const endsAt = moment(timer.ends_at + " GMT+0000").tz(timer.time_zone);
//       return this.formatDate(endsAt);
//     } else if (timer.timer_type === "perpetual") {
//       const durationObject = timerHelper.getDurationObject(timer.duration);
//       const { days, hours, minutes, seconds } = durationObject;
//       return `Perpetual (${days}d ${hours}h ${minutes}m)`;
//     } else if (timer.timer_type === "dynamic") {
//       return "(Dynamic Timer)";
//     }else if (timer.timer_type === "recurring") {
//       return "(Recurring Timer)";
//     } else {
//       return null;
//     }
//   };

//   renderTimerCurry = (showUid) => {
//     return (timer) => {
//       const timerUrl = timer.url;
//       const createdAt = moment(timer.created_at).tz(timer.time_zone);
//       const endsAt = moment(timer.ends_at).tz(timer.time_zone);
//       const isExpired = endsAt.isBefore(moment()) && !timer.archived && timer.timer_type === "standard";
//       const features = timerHelper.getTimerFeatures(timer);
//       const missingFeatures = timerHelper.getMissingFeatures(features, this.props.user);

//       return (
//         <Table.Row className={isExpired ? css.expired : ""} key={"timer_" + timer.timer_id}>
//           <Table.Cell className={tableCss.name}>
//             {timer.name}
//             <Popup
//               basic
//               position="right center"
//               trigger={<Icon name="eye" />}
//               content={<TimerPreview type="Timer" timer={timer} genUrl={this.props.envContext.gen_url} />}
//             />
//             {missingFeatures.length && this.props.user.subscription ? (
//               <Popup
//                 basic
//                 position="right center"
//                 trigger={<Icon color="red" name="exclamation circle" />}
//                 content={"This timer uses features not included in your plan."}
//               />
//             ) : null}
//             {this.renderDisableAtWarning(timer)}
//           </Table.Cell>
//           {showUid ? <Table.Cell>{timer.uid}</Table.Cell> : null}
//           <Table.Cell>{this.format24HoursDate(createdAt)}</Table.Cell>
//           <Table.Cell>{this.renderEndDate(timer)}</Table.Cell>
//           <Table.Cell>{timer.total_views}</Table.Cell>
//           <Table.Cell>{timer.paid_views}</Table.Cell>
//           <Table.Cell className={tableCss.actionDiv}>
//             {this.renderEditIcon(timer.archived, timer)}
//             {this.renderArchiveIcon(timer.archived, timer.timer_id)}
//             {this.renderCopyLink(timerUrl)}
//           </Table.Cell>
//         </Table.Row>
//       );
//     };
//   };

//   renderDisableAtWarning = (timer) => {
//     if (!timer.disableAt) {
//       return null;
//     }
//     var text;
//     if (moment(timer.disableAt).isAfter()) {
//       // Timer will be disabled in the future
//       text = `This timer exceeds our free limit and will be disabled ${moment(timer.disableAt).fromNow()}.`;
//     } else {
//       text = "This timer exceeds our free limit and has been disabled.";
//     }
//     return (
//       <Popup basic position="right center" trigger={<Icon color="red" name="exclamation circle" />} content={text} />
//     );
//   };

//   searchTextHandler = (e) => {
//     this.setState({searchText:e.target.value});
//   }

//   renderTimers = (timers, showUid) => {
//     // const orderedTimers = _.orderBy(timers, [this.state.currentSort.value, "name"], [this.state.currentSort.sort, "asc"]);
//     // return _.map(orderedTimers, this.renderTimerCurry(showUid));
//     return _.map(timers, this.renderTimerCurry(showUid));
//   };

//   calculateTotalPages = (length,limit) => {
//       let totalPage = Math.floor(length / limit);
//       if(length % limit != 0){
//           totalPage++;
//       }
//       return totalPage;
//   }

//   renderTableContents = (status) => {
//     let allTimers = this.props.timers["account_timers"];
//     // const timers = this.props.timers[status];
//     let timers = _.filter(allTimers,(timer)=>{
//       if(status == "active"){
//         return !timer.archived;
//       }else{
//         return timer.archived;
//       }
//     });
//     let filteredTimers = [...timers];
//     if(this.isSubaccountUser()){
//       filteredTimers = _.filter(timers,(timer) => {
//         return timer.subaccount_id == this.props.user.subaccount_id;
//       });
//     }else if(this.isCorporateUser()){
//       // Check the selected current subaccount is a valid account.
//       if(this.state.current_subaccount_id!=-1){
//         filteredTimers = _.filter(timers,(timer) => {
//           return timer.subaccount_id == this.state.current_subaccount_id;
//         });
//       }
//     }
//     if(this.state.searchText.length != 0){
//       filteredTimers = _.filter(filteredTimers,(timer) => {
//         return timer.name.toLowerCase().includes(this.state.searchText.toLowerCase());
//       });
//     }
//     const showUid = _.some(filteredTimers, (timer) => timer.uid);
//     if (_.isEmpty(filteredTimers)) {
//       return <>
//         <div className={tableCss.filterDiv}>
//           { this.isCorporateUser() && <>
//             <div>
//                <Dropdown placeholder='Subaccounts' defaultValue={this.state.current_subaccount_id} onChange={this.subaccountChangeHandler} selection options={this.state.subaccounts} />
//             </div>
//             <div>
//               <Input className={gridCss.gridSearchText} icon iconPosition='left'  placeholder='Search'>
//                 <input value={this.state.searchText} onChange={this.searchTextHandler}/>
//                 <Icon name='search' />
//               </Input>
//             </div>
//           </> }
//           { !this.isCorporateUser() && <>
//             <div>
//               <Input className={gridCss.gridSearchText} icon iconPosition='left'  placeholder='Search'>
//                 <input value={this.state.searchText} onChange={this.searchTextHandler}/>
//                 <Icon name='search' />
//               </Input>
//             </div>
//             <div>
//               <span style={{paddingRight:'16px'}}>Sort by</span>
//               <Dropdown placeholder='Sort' defaultValue={this.state.currentSort.value} onChange={this.sortChangeHandler} selection options={this.state.sortOptions} />
//             </div>
//           </>}

//         </div>
//         {this.renderZeroState(status)}
//       </>
//     } else {
//       // Pagination Logic as well as Counting
//       let totalTimer = filteredTimers.length;
//       let totalPages = this.calculateTotalPages(totalTimer,this.state.recordLimit);
//       let currentActivePage = this.state.timerActivePage > totalPages ? 1 : this.state.timerActivePage;
//       let displayTotalTimer = this.state.recordLimit;
//       if(displayTotalTimer > totalTimer){
//         displayTotalTimer = totalTimer;
//       }else{
//           let count = this.state.recordLimit * this.state.timerActivePage;
//           if(count > totalTimer){
//             displayTotalTimer = totalTimer - ( this.state.recordLimit * (this.state.timerActivePage - 1));
//           }
//       }

//       // Logic to filtered Items according to their page size
//       let beginIndex = (currentActivePage - 1) * this.state.recordLimit;
//       let endIndex = beginIndex + displayTotalTimer;

//       // Sorting logic must be placed here.
//       filteredTimers.sort((value1,value2) => {
//         const sortValue = this.state.currentSort.value;
//         if(sortValue.indexOf("at")!=-1){
//           return new Date(value2[sortValue]).getTime() - new Date(value1[sortValue]).getTime();
//         }else{
//           return parseInt(value2[sortValue]) - parseInt(value1[sortValue]);
//         }
//       });

//       // Slicing logic happened
//       filteredTimers = _.slice(filteredTimers,beginIndex,endIndex)

//       return (
//         <>
//           <div className={tableCss.filterDiv}>
//             { this.isCorporateUser() && <>
//               <div>
//                 { <Dropdown placeholder='Subaccounts' defaultValue={this.state.current_subaccount_id} onChange={this.subaccountChangeHandler} selection options={this.state.subaccounts} />}
//               </div>
//               <div>
//                 <Input className={gridCss.gridSearchText} icon iconPosition='left'  placeholder='Search'>
//                   <input value={this.state.searchText} onChange={this.searchTextHandler}/>
//                   <Icon name='search' />
//                 </Input>
//               </div>
//             </> }
//             { !this.isCorporateUser() && <>
//               <div>
//                 <Input className={gridCss.gridSearchText} icon iconPosition='left'  placeholder='Search'>
//                   <input value={this.state.searchText} onChange={this.searchTextHandler}/>
//                   <Icon name='search' />
//                 </Input>
//               </div>
//               <div>
//                 <span style={{paddingRight:'16px'}}>Sort by</span>
//                 <Dropdown placeholder='Sort' defaultValue={this.state.currentSort.value} onChange={this.sortChangeHandler} selection options={this.state.sortOptions} />
//               </div>
//             </>}
//           </div>

//           <Table basic className={tableCss.tableBorder}>
//             <Table.Header>
//               <Table.Row>
//                 <Table.HeaderCell>Name</Table.HeaderCell>
//                 {showUid ? <Table.HeaderCell>UID</Table.HeaderCell> : null}
//                 <Table.HeaderCell>Creation date</Table.HeaderCell>
//                 <Table.HeaderCell>End date</Table.HeaderCell>
//                 <Table.HeaderCell>Total views</Table.HeaderCell>
//                 <Table.HeaderCell>Views
//                   <span className={tableCss.secondary}>(this billing cycle)</span>
//                 </Table.HeaderCell>
//                 <Table.HeaderCell>Actions</Table.HeaderCell>
//               </Table.Row>
//             </Table.Header>

//             <Table.Body>
//             <Table.Row className={tableCss.tableRowBackground}>
//               <Table.HeaderCell className={tableCss.tableCol} colSpan={showUid ? '7' : '6'}>
//                 {totalTimer} total timers
//               </Table.HeaderCell>
//             </Table.Row>
//               {this.renderTimers(filteredTimers, showUid)}
//             </Table.Body>
//           </Table>
//           <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
//             <div>
//                 <span className={tableCss.tableCol}>{displayTotalTimer} out of {totalTimer}</span>
//             </div>
//             <div>
//                 <Pagination  defaultActivePage={currentActivePage} onPageChange={this.handleTimerPaginationChange} totalPages={totalPages} lastItem={null} firstItem={null}
//                     ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
//                     prevItem={{ content: <span><LeftArrowIconComponent/></span>, icon: true }}
//                     nextItem={{ content: <span><RightArrowIconComponent/></span>, icon: true }}/>
//             </div>
//           </div>
//         </>
//       );
//     }
//   };

//   renderTable = (status) => {
//     return () => {
//       // return <Tab.Pane as='div' attached={false}>{this.renderTableContents(status)}</Tab.Pane>;
//       return <Tab.Pane as='div' attached="false">{this.renderTableContents(status)}</Tab.Pane>;
//     };
//   };

//   renderTabs = () => {
//     const tables = [
//       { menuItem: "Active Timers", render: this.renderTable("active") },
//       { menuItem: "Archived Timers", render: this.renderTable("archived") },
//     ];

//     return <Tab menu={{ secondary: true, pointing: true }} panes={tables}/>;
//   };

//   renderCreateTimerButton() {
//     return (
//       <CreateTimerButtonContainer>
//         {/* <Icon name="plus" /> */}
//         Create New
//       </CreateTimerButtonContainer>

//     );
//   }

//   subaccountChangeHandler = (event,data) => {
//     this.setState( {current_subaccount_id: data.value});
//   }

//   sortChangeHandler = (event,data) => {
//     this.setState( {currentSort: data});
//   }

//   render() {
//     return (
//       <>
//         <div id="timer-list" className={tableCss.tableContainer}>
//           <Header as="h1">Timers</Header>
//           <div className={tableCss.tableActions}>
//             {this.renderCreateTimerButton()}
//             {this.props.user.subscription ? null : <UpgradePlanButton>Upgrade Plan</UpgradePlanButton>}
//           </div>
//           {this.renderTabs()}
//         </div>
//       </>
//     );
//   }
// }

// export default TimerList;

import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import _ from "lodash";
import { createBrowserHistory } from "history";

import moment from "moment-timezone";

import {
  Header,
  Table,
  Tab,
  Popup,
  Icon,
  Dropdown,
  Input,
  Pagination,
} from "semantic-ui-react";
import CreateTimerButtonContainer from "../../containers/createTimerButtonContainer";
import Clipboard from "react-clipboard.js";
import UpgradePlanButton from "../billing/upgradePlanButton";

import timerHelper from "../../helpers/timers/timerHelper";
import TimerPreview from "../shared/timerPreview";

import css from "../../assets/styles/timerList/index.module.scss";
import tableCss from "../../assets/styles/shared/tables.module.scss";

import gridCss from "../../assets/styles/shared/grids.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  DeleteIconComponent,
  EditIconComponent,
  LeftArrowIconComponent,
  RightArrowIconComponent,
} from "../iconComponents";
import CreateTimerButton from "./createTimerButton";

const browserHistory = createBrowserHistory();

const { object, array } = PropTypes;

const TimerList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const timezone = moment.tz.guess();
  const now = moment().tz(timezone);
  const nearestHour =
    now.minute() || now.second() || now.millisecond()
      ? now.add(1, "hour").startOf("hour")
      : now.startOf("hour");

  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [timerName, setTimerName] = useState("");
  const [dateTime, setDateTime] = useState(nearestHour);
  const [trialCreate, setTrialCreate] = useState(true);
  const [subaccounts, setSubaccounts] = useState([
    { key: "drop_subaccount_0", text: "All", value: -1 },
    { key: "drop_subaccount_00", text: "Corporate Timers", value: 0 },
  ]);
  const [currentSubaccountId, setCurrentSubaccountId] = useState(-1);
  const [totalTimer, setTotalTimer] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [recordLimit, setRecordLimit] = useState(10);
  const [timerActivePage, setTimerActivePage] = useState(1);
  const [sortOptions] = useState([
    { key: 111001, text: "Creation date", value: "created_at", sort: "desc" },
    { key: 111002, text: "End date", value: "ends_at", sort: "desc" },
    { key: 111003, text: "Total views", value: "total_views", sort: "desc" },
    {
      key: 111004,
      text: "Views (this billing cycle)",
      value: "paid_views",
      sort: "desc",
    },
  ]);
  const [currentSort, setCurrentSort] = useState({
    key: 111001,
    text: "Creation date",
    value: "created_at",
    sort: "desc",
  });

  const isCorporateUser = () => {
    return (
      props.user.is_company ||
      (props.user.features &&
        props.user.features.subaccount &&
        props.user.parent_id == 0)
    );
  };

  const isSubaccountUser = () => {
    return props.user.subaccount_id != 0;
  };

  useEffect(() => {
    props.fetch();
    if (isCorporateUser()) {
      props.fetchSubaccounts();
    }
  }, []);

  useEffect(() => {
    if (props.subaccounts && props.subaccounts.length > 0) {
      if (subaccounts.length != props.subaccounts.length) {
        const subaccountOptions = _.map(
          props.subaccounts,
          (subaccount, index) => ({
            key: "drop_subaccount_" + subaccount.id,
            text: subaccount.name,
            value: subaccount.id,
          })
        );
        subaccountOptions.unshift(
          { key: "drop_subaccount_0", text: "All", value: -1 },
          { key: "drop_subaccount_00", text: "Corporate Timers", value: 0 }
        );
        setSubaccounts([...subaccountOptions]);
      }
    }
  }, [props.subaccounts]);

  useEffect(() => {
    props.fetch();
  }, [props.user.tier]);

  const handleEdit = (timerId) => {
    return () => {
      navigate("/timer/" + timerId);
      //browserHistory.push("/timer/" + timerId);
    };
  };

  const handleCreateTimer = (options) => {
    props.create(options);
  };

  const formatDate = (date) => {
    return moment(date).format("MMM DD, YYYY hh:mm A");
  };

  const format24HoursDate = (date) => {
    return moment(date).format("MMM DD, YYYY HH:mm");
  };

  const handleArchive = (timerId, archived) => {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();
      props.archive(timerId, !archived);
    };
  };

  const renderEditIcon = (archived, timer) => {
    if (archived) {
      return null;
    }

    return (
      <Popup
        inverted
        trigger={
          <span
            style={{ cursor: "pointer" }}
            onClick={handleEdit(timer.timer_id)}
          >
            <EditIconComponent />
          </span>
        }
        content={"Edit"}
        position={"top center"}
      />
    );
  };

  const renderArchiveIcon = (archived, id) => {
    const info = archived ? "Unarchive" : "Archive";

    return (
      <Popup
        inverted
        trigger={
          <span
            style={{ cursor: "pointer" }}
            name="trash"
            onClick={handleArchive(id, archived)}
          >
            <DeleteIconComponent />
          </span>
        }
        content={info}
        position={"top center"}
      />
    );
  };

  const renderCopyLink = (url) => {
    if (url) {
      const copyButton = (
        <Clipboard component="span" data-clipboard-text={url}>
          <Icon name="linkify" className={tableCss.linkIcon} />
        </Clipboard>
      );

      return (
        <Popup
          inverted
          trigger={copyButton}
          content="Copied!"
          position="top center"
          hideOnScroll
        />
      );
    } else {
      return (
        <Popup
          inverted
          content="Upgrade your plan to use this timer"
          position="top center"
          hideOnScroll
          trigger={<Icon name="linkify" disabled />}
        />
      );
    }
  };

  const handleTimerPaginationChange = (e, { activePage }) =>
    setTimerActivePage(activePage);

  const renderZeroState = (status) => {
    if (status === "active") {
      return (
        <div className={tableCss.zero}>
          You do not currently have any active timers. Create one with the
          button in the top right!
        </div>
      );
    } else {
      return (
        <div className={tableCss.zero}>
          You do not currently have any archived timers.
        </div>
      );
    }
  };

  // const renderEndDate = (timer) => {
  //   if (timer.timer_type === "standard") {
  //     const endsAt = moment(timer.ends_at + " GMT+0000").tz(timer.time_zone);
  //     return formatDate(endsAt);
  //   } else if (timer.timer_type === "perpetual") {
  //     const durationObject = timerHelper.getDurationObject(timer.duration);
  //     const { days, hours, minutes, seconds } = durationObject;
  //     return `Perpetual (${days}d ${hours}h ${minutes}m)`;
  //   } else if (timer.timer_type === "dynamic") {
  //     return "(Dynamic Timer)";
  //   } else if (timer.timer_type === "recurring") {
  //     return "(Recurring Timer)";
  //   } else {
  //     return null;
  //   }
  // };

  const renderEndDate = (timer) => {
    if (timer.timer_type === "standard") {
      // const endsAt = moment(timer.ends_at + " GMT+0000").tz(timer.time_zone);
      // // console.log("Ends At: ",endsAt," Timer ends at: ",timer.ends_at,timer.time_zone);
      // return formatDate(endsAt);
      const endsAt = moment.tz(timer.ends_at, timer.time_zone);
      return endsAt.format("MMM DD, YYYY HH:mm");
    } else if (timer.timer_type === "perpetual") {
      const durationObject = timerHelper.getDurationObject(timer.duration);
      const { days, hours, minutes, seconds } = durationObject;
      return `Perpetual (${days}d ${hours}h ${minutes}m)`;
    } else if (timer.timer_type === "dynamic") {
      return "(Dynamic Timer)";
    } else if (timer.timer_type === "recurring") {
      return "(Recurring Timer)";
    } else {
      return null;
    }
  };

  const renderTimerCurry = (showUid) => {
    return (timer) => {
      const timerUrl = timer.url;
      const createdAt = moment(timer.created_at).tz(timer.time_zone);
      const endsAt = moment(timer.ends_at).tz(timer.time_zone);
      const isExpired =
        endsAt.isBefore(moment()) &&
        !timer.archived &&
        timer.timer_type === "standard";
      const features = timerHelper.getTimerFeatures(timer);
      const missingFeatures = timerHelper.getMissingFeatures(
        features,
        props.user
      );

      return (
        <Table.Row
          className={isExpired ? css.expired : ""}
          key={"timer_" + timer.timer_id}
        >
          <Table.Cell className={tableCss.name}>
            {timer.name}
            <Popup
              basic
              position="right center"
              trigger={<Icon name="eye" />}
              content={
                <TimerPreview
                  type="Timer"
                  timer={timer}
                  genUrl={props.user.auth.gen_url}
                />
              }
            />
            {missingFeatures.length && props.user.subscription ? (
              <Popup
                basic
                position="right center"
                trigger={<Icon color="red" name="exclamation circle" />}
                content={"This timer uses features not included in your plan."}
              />
            ) : null}
            {renderDisableAtWarning(timer)}
          </Table.Cell>
          {showUid ? <Table.Cell>{timer.uid}</Table.Cell> : null}
          <Table.Cell>{format24HoursDate(createdAt)}</Table.Cell>
          <Table.Cell>{renderEndDate(timer)}</Table.Cell>
          <Table.Cell>{timer.total_views}</Table.Cell>
          <Table.Cell>{timer.paid_views}</Table.Cell>
          <Table.Cell className={tableCss.actionDiv}>
            {renderEditIcon(timer.archived, timer)}
            {renderArchiveIcon(timer.archived, timer.timer_id)}
            {renderCopyLink(timerUrl)}
          </Table.Cell>
        </Table.Row>
      );
    };
  };

  const renderDisableAtWarning = (timer) => {
    if (!timer.disableAt) {
      return null;
    }
    var text;
    if (moment(timer.disableAt).isAfter()) {
      text = `This timer exceeds our free limit and will be disabled ${moment(
        timer.disableAt
      ).fromNow()}.`;
    } else {
      text = "This timer exceeds our free limit and has been disabled.";
    }
    return (
      <Popup
        basic
        position="right center"
        trigger={<Icon color="red" name="exclamation circle" />}
        content={text}
      />
    );
  };

  const renderTimers = (timers, showUid) => {
    return _.map(timers, renderTimerCurry(showUid));
  };

  const calculateTotalPages = (length, limit) => {
    let totalPage = Math.floor(length / limit);
    if (length % limit != 0) {
      totalPage++;
    }
    return totalPage;
  };

  const renderTableContents = (status) => {
    let allTimers = props.timers["account_timers"];
    let timers = _.filter(allTimers, (timer) => {
      if (status == "active") {
        return !timer.archived;
      } else {
        return timer.archived;
      }
    });
    let filteredTimers = [...timers];
    if (isSubaccountUser()) {
      filteredTimers = _.filter(timers, (timer) => {
        return timer.subaccount_id == props.user.subaccount_id;
      });
    } else if (isCorporateUser()) {
      if (currentSubaccountId != -1) {
        filteredTimers = _.filter(timers, (timer) => {
          return timer.subaccount_id == currentSubaccountId;
        });
      }
    }
    if (searchText.length != 0) {
      filteredTimers = _.filter(filteredTimers, (timer) => {
        return timer.name.toLowerCase().includes(searchText.toLowerCase());
      });
    }
    const showUid = _.some(filteredTimers, (timer) => timer.uid);
    if (_.isEmpty(filteredTimers)) {
      return (
        <>
          <div className={tableCss.filterDiv}>
            {isCorporateUser() && (
              <>
                <div>
                  <Dropdown
                    placeholder="Subaccounts"
                    defaultValue={currentSubaccountId}
                    onChange={(e, data) => setCurrentSubaccountId(data.value)}
                    selection
                    options={subaccounts}
                  />
                </div>
                <div>
                  <Input
                    className={gridCss.gridSearchText}
                    icon
                    iconPosition="left"
                    placeholder="Search"
                  >
                    <input
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Icon name="search" />
                  </Input>
                </div>
              </>
            )}
            {!isCorporateUser() && (
              <>
                <div>
                  <Input
                    className={gridCss.gridSearchText}
                    icon
                    iconPosition="left"
                    placeholder="Search"
                  >
                    <input
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Icon name="search" />
                  </Input>
                </div>
                <div>
                  <span style={{ paddingRight: "16px" }}>Sort by</span>
                  <Dropdown
                    placeholder="Sort"
                    defaultValue={currentSort.value}
                    onChange={(e, data) => setCurrentSort(data)}
                    selection
                    options={sortOptions}
                  />
                </div>
              </>
            )}
          </div>
          {renderZeroState(status)}
        </>
      );
    } else {
      let totalTimer = filteredTimers.length;
      let totalPages = calculateTotalPages(totalTimer, recordLimit);
      let currentActivePage =
        timerActivePage > totalPages ? 1 : timerActivePage;
      let displayTotalTimer = recordLimit;
      if (displayTotalTimer > totalTimer) {
        displayTotalTimer = totalTimer;
      } else {
        let count = recordLimit * timerActivePage;
        if (count > totalTimer) {
          displayTotalTimer = totalTimer - recordLimit * (timerActivePage - 1);
        }
      }

      let beginIndex = (currentActivePage - 1) * recordLimit;
      let endIndex = beginIndex + displayTotalTimer;

      filteredTimers.sort((value1, value2) => {
        const sortValue = currentSort.value;
        if (sortValue.indexOf("at") != -1) {
          return (
            new Date(value2[sortValue]).getTime() -
            new Date(value1[sortValue]).getTime()
          );
        } else {
          return parseInt(value2[sortValue]) - parseInt(value1[sortValue]);
        }
      });

      filteredTimers = _.slice(filteredTimers, beginIndex, endIndex);

      return (
        <>
          <div className={tableCss.filterDiv}>
            {isCorporateUser() && (
              <>
                <div>
                  {
                    <Dropdown
                      placeholder="Subaccounts"
                      defaultValue={currentSubaccountId}
                      onChange={(e, data) => setCurrentSubaccountId(data.value)}
                      selection
                      options={subaccounts}
                    />
                  }
                </div>
                <div>
                  <Input
                    className={gridCss.gridSearchText}
                    icon
                    iconPosition="left"
                    placeholder="Search"
                  >
                    <input
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Icon name="search" />
                  </Input>
                </div>
              </>
            )}
            {!isCorporateUser() && (
              <>
                <div>
                  <Input
                    className={gridCss.gridSearchText}
                    icon
                    iconPosition="left"
                    placeholder="Search"
                  >
                    <input
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <Icon name="search" />
                  </Input>
                </div>
                <div>
                  <span style={{ paddingRight: "16px" }}>Sort by</span>
                  <Dropdown
                    placeholder="Sort"
                    defaultValue={currentSort.value}
                    onChange={(e, data) => setCurrentSort(data)}
                    selection
                    options={sortOptions}
                  />
                </div>
              </>
            )}
          </div>

          <Table basic className={tableCss.tableBorder}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                {showUid ? <Table.HeaderCell>UID</Table.HeaderCell> : null}
                <Table.HeaderCell>Creation date</Table.HeaderCell>
                <Table.HeaderCell>End date</Table.HeaderCell>
                <Table.HeaderCell>Total views</Table.HeaderCell>
                <Table.HeaderCell>
                  Views
                  <span className={tableCss.secondary}>
                    (this billing cycle)
                  </span>
                </Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row className={tableCss.tableRowBackground}>
                <Table.HeaderCell
                  className={tableCss.tableCol}
                  colSpan={showUid ? "7" : "6"}
                >
                  {totalTimer} total timers
                </Table.HeaderCell>
              </Table.Row>
              {renderTimers(filteredTimers, showUid)}
            </Table.Body>
          </Table>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <span className={tableCss.tableCol}>
                {displayTotalTimer} out of {totalTimer}
              </span>
            </div>
            <div>
              <Pagination
                defaultActivePage={currentActivePage}
                onPageChange={handleTimerPaginationChange}
                totalPages={totalPages}
                lastItem={null}
                firstItem={null}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                prevItem={{
                  content: (
                    <span>
                      <LeftArrowIconComponent />
                    </span>
                  ),
                  icon: true,
                }}
                nextItem={{
                  content: (
                    <span>
                      <RightArrowIconComponent />
                    </span>
                  ),
                  icon: true,
                }}
              />
            </div>
          </div>
        </>
      );
    }
  };

  const renderTable = (status) => {
    return () => {
      return (
        <Tab.Pane as="div" attached="false">
          {renderTableContents(status)}
        </Tab.Pane>
      );
    };
  };

  const renderTabs = () => {
    const tables = [
      { menuItem: "Active Timers", render: renderTable("active") },
      { menuItem: "Archived Timers", render: renderTable("archived") },
    ];

    return <Tab menu={{ secondary: true, pointing: true }} panes={tables} />;
  };

  const renderCreateTimerButton = () => {
    return <CreateTimerButtonContainer>Create New</CreateTimerButtonContainer>;
  };

  return (
    <>
      <div id="timer-list" className={tableCss.tableContainer}>
        <Header as="h1">Timers</Header>
        <div className={tableCss.tableActions}>
          {renderCreateTimerButton()}
          {props.user.subscription ? null : (
            <UpgradePlanButton>Upgrade Plan</UpgradePlanButton>
          )}
        </div>
        {renderTabs()}
      </div>
    </>
  );
};

TimerList.propTypes = {
  timers: object,
  templates: array,
  envContext: object,
  user: object,
  subaccounts: array,
};

export default TimerList;
