import axios from "axios";
import React from "react";
import Cookies from "js-cookie";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: null, countdown: 5 };
    this.timer = null;
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error) {
    const jwt = Cookies.get("jwt_token");
    axios
      .post(
        `${process.env.REACT_APP_DOMAIN_URL}/api/v1/record_application_error`,
        {
          status_code: 500,
          error_message: `${error.toString()} on page ${window.location.href}`,
        },
        {
          headers: { Authorization: "Bearer " + jwt },
        }
      )
      .catch(() => {
        console.log("Redirect detected");
      });
  }

  componentDidUpdate(_, prevState) {
    if (
      this.state.hasError &&
      this.state.countdown > 0 &&
      prevState.countdown !== this.state.countdown
    ) {
      this.timer = setTimeout(() => {
        this.setState((prevState) => ({ countdown: prevState.countdown - 1 }));
      }, 1000);
    }
  }

  componentDidMount() {
    if (this.state.hasError) {
      this.timer = setTimeout(() => {
        this.setState((prevState) => ({ countdown: prevState.countdown - 1 }));
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    if (this.state.hasError) {
      if (this.state.countdown === 0) {
        window.location.href = "/dashboard";
      }
      return (
        <div style={{ padding: "2rem", textAlign: "center" }}>
          <h1>Something went wrong.</h1>
          <p>We’re working to fix this issue. Please try again later.</p>
          <p>
            Redirecting to <a href="/dashboard">Dashboard</a> in{" "}
            {this.state.countdown} Seconds
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
