// import { LOCATION_CHANGE } from "react-router-redux";
// import _ from "lodash";

// import {
//   TEMPLATE_SAVE_SUCCESS,
//   TEMPLATE_SAVE_FAILURE,
//   TEMPLATE_DESTROY_SUCCESS,
//   TEMPLATE_DESTROY_FAILURE,
//   TEMPLATE_COPY_SUCCESS,
//   TEMPLATE_COPY_FAILURE,
//   TEMPLATE_FETCH_SUCCESS,
//   TEMPLATE_FETCH_FAILURE,
//   TEMPLATE_SAVE_REQUEST,
// } from "../constants/templateConstants";

// const upsertTemplate = (currentTemplates, newTemplate) => {
//   _.remove(currentTemplates, (template) => {
//     return template.id === newTemplate.id;
//   });

//   currentTemplates.push(newTemplate);
//   return currentTemplates;
// };

// const removeTemplate = (currentTemplates, templateId) => {
//   _.remove(currentTemplates, (template) => {
//     return template.id === templateId;
//   });
//   return currentTemplates;
// };

// export const initialState = {
//   data: [],
//   error: null,
//   isTemplateSubmitting: false,
// };

// export default (state = initialState, action) => {
//   let updatedData;
//   // console.log(action);
//   switch (action.type) {
//     case TEMPLATE_SAVE_REQUEST:
//       return { ...state, isTemplateSubmitting: true, error: null };
//     case TEMPLATE_FETCH_SUCCESS:
//       return { ...state, data: action.data, error: null };
//     case TEMPLATE_FETCH_FAILURE:
//       return { ...state, error: action.error };
//     case TEMPLATE_SAVE_SUCCESS:
//       updatedData = upsertTemplate(state.data, action.data);
//       return {
//         ...state,
//         data: updatedData,
//         isTemplateSubmitting: false,
//         error: null,
//       };
//     case TEMPLATE_SAVE_FAILURE:
//       return { ...state, isTemplateSubmitting: false, error: action.error };
//     case TEMPLATE_DESTROY_SUCCESS:
//       updatedData = removeTemplate(state.data, action.id);
//       return { ...state, data: updatedData, error: null };
//     case TEMPLATE_DESTROY_FAILURE:
//       return { ...state, error: action.error };
//     default:
//       return state;
//   }
// };

import { LOCATION_CHANGE } from "react-router-redux";
import _ from "lodash";

import {
  TEMPLATE_SAVE_SUCCESS,
  TEMPLATE_SAVE_FAILURE,
  TEMPLATE_DESTROY_SUCCESS,
  TEMPLATE_DESTROY_FAILURE,
  TEMPLATE_COPY_SUCCESS,
  TEMPLATE_COPY_FAILURE,
  TEMPLATE_FETCH_SUCCESS,
  TEMPLATE_FETCH_FAILURE,
  TEMPLATE_SAVE_REQUEST,
} from "../constants/templateConstants";

// Helper function to upsert a template immutably
const upsertTemplate = (currentTemplates, newTemplate) => {
  // Remove the template if it exists and return a new array
  const updatedTemplates = currentTemplates.filter(
    (template) => template.id !== newTemplate.id
  );

  // Add the new/updated template
  return [...updatedTemplates, newTemplate];
};

// Helper function to remove a template immutably
const removeTemplate = (currentTemplates, templateId) => {
  // Return a new array excluding the template with the given id
  return currentTemplates.filter((template) => template.id !== templateId);
};

export const initialState = {
  data: [],
  error: null,
  isTemplateSubmitting: false,
};

export default (state = initialState, action) => {
  let updatedData;
  // console.log(action);
  switch (action.type) {
    case TEMPLATE_SAVE_REQUEST:
      return { ...state, isTemplateSubmitting: true, error: null };
    case TEMPLATE_FETCH_SUCCESS:
      return { ...state, data: action.data, error: null };
    case TEMPLATE_FETCH_FAILURE:
      return { ...state, error: action.error };
    case TEMPLATE_SAVE_SUCCESS:
      updatedData = upsertTemplate(state.data, action.data);
      return {
        ...state,
        data: updatedData,
        isTemplateSubmitting: false,
        error: null,
      };
    case TEMPLATE_SAVE_FAILURE:
      return { ...state, isTemplateSubmitting: false, error: action.error };
    case TEMPLATE_DESTROY_SUCCESS:
      updatedData = removeTemplate(state.data, action.id);
      return { ...state, data: updatedData, error: null };
    case TEMPLATE_DESTROY_FAILURE:
      return { ...state, error: action.error };
    default:
      return state;
  }
};
