import _ from "lodash";
import { connect } from "react-redux";

import Billing from "../components/billing";
import { fetch } from "../actions/timerActions";
import {
  subscribe,
  createStripeCheckoutSession,
} from "../actions/authenticationActions";
import timerHelper from "../helpers/timers/timerHelper";

const mapStateToProps = (state, props) => {
  const activeTimers = _.get(state, "timers.data.active", []);
  const archivedTimers = _.get(state, "timers.data.archived", []);
  const allTimers = [...activeTimers, ...archivedTimers];

  let draftFeatures = [];

  if (props.draftTimer) {
    draftFeatures = timerHelper.getFeatures(props.draftTimer);
  }
  // Get features used by all timers
  const usedFeatures = _.union(..._.map(allTimers, timerHelper.getFeatures));

  // // console.log(usedFeatures,"usedFeatures");

  // console.log(allTimers);
  return {
    usedFeatures: usedFeatures, // Replace 'someReducer' with the actual reducer name
    // usedFeatures: state.someReducer?.usedFeatures || usedFeatures, // Replace 'someReducer' with the actual reducer name
    draftFeatures: state.someReducer?.draftFeatures || [], // Replace 'someReducer' with the actual reducer name
    user: state.auth?.data || {},
    envContext: state.envContext?.data || {},
    inProgress: state.auth?.inProgress || false,
    authData: state.auth || {},
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetch: () => {
    dispatch(fetch());
  },
  subscribe: (stripeToken, plan) => {
    dispatch(subscribe(stripeToken, plan));
  },
  createStripeCheckoutSession: (plan) =>
    dispatch(createStripeCheckoutSession(plan)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
