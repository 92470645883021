export const SUBACCOUNT_DETAILS_FETCH_SUCCESS = "SUBACCOUNT_DETAILS_FETCH_SUCCESS";
export const SUBACCOUNT_DETAILS_FETCH_FAILURE = "SUBACCOUNT_DETAILS_FETCH_FAILURE";

export const SUBACCOUNT_USER_DETAILS_FETCH_SUCCESS = "SUBACCOUNT_USER_DETAILS_FETCH_SUCCESS";
export const SUBACCOUNT_USER_DETAILS_FETCH_FAILURE = "SUBACCOUNT_USER_DETAILS_FETCH_FAILURE";

export const SUBACCOUNT_USER_CREATE_SUCCESS = "SUBACCOUNT_USER_CREATE_SUCCESS";
export const SUBACCOUNT_USER_CREATE_FAILURE = "SUBACCOUNT_USER_CREATE_FAILURE";

export const SUBACCOUNT_TIMERS_FETCH_SUCCESS = "SUBACCOUNT_TIMERS_FETCH_SUCCESS";
export const SUBACCOUNT_TIMERS_FETCH_FAILURE = "SUBACCOUNT_TIMERS_FETCH_FAILURE";

export const SUBACCOUNT_NAME_UPDATE_SUCCESS = "SUBACCOUNT_NAME_UPDATE_SUCCESS";
export const SUBACCOUNT_NAME_UPDATE_FAILURE = "SUBACCOUNT_NAME_UPDATE_FAILURE";

export const SUBACCOUNT_LATEST_TIMER_FETCH_SUCCESS = "SUBACCOUNT_LATEST_TIMER_FETCH_SUCCESS";
export const SUBACCOUNT_LATEST_TIMER_FETCH_FAILURE = "SUBACCOUNT_LATEST_TIMER_FETCH_FAILURE";