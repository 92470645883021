import { push } from "react-router-redux";
import axios from "axios";
import Cookies from "js-cookie";
import generateApiClient from "../helpers/generateApiClient";
import getErrorMessage from "../helpers/getErrorMessage";
import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAILURE,
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_FAILURE,
  // SUBSCRIBE_UPDATE_SUCCESS,
  SUBSCRIBE_UPDATE_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  BETA_SIGNUP_SUCCESS,
  BETA_SIGNUP_FAILURE,
  AUTH_SUBSCRIBE_SUCCESS,
  AUTH_SUBSCRIBE_FAILURE,
  AUTH_INVOICES_SUCCESS,
  AUTH_INVOICES_FAILURE,
  AUTH_IN_PROGRESS,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_SETTINGS_SUCCESS,
  UPDATE_SETTINGS_FAILURE,
  UPDATE_ENV,
  USER_FETCH_SUCCESS,
  USER_FETCH_FAILURE,
  USER_ACCOUNT_ACTIVATION_SUCCESS,
  USER_ACCOUNT_ACTIVATION_FAILURE,
  UPLOAD_FILE_PROGRESS,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  CLEAR_AUTH_ERROR,
  FORGOT_PASSWORD_REQUEST,
  UPDATE_CAN_EDIT_TEMPLATES,
  AUTH_TOKEN,
} from "../constants/authenticationConstants";

const loginSuccess = (data) => ({ type: AUTH_LOGIN_SUCCESS, data });
const loginFailure = (error) => ({ type: AUTH_LOGIN_FAILURE, error });

const logoutSuccess = (_data) => ({ type: AUTH_LOGOUT_SUCCESS });
const logoutFailure = (_data) => ({ type: AUTH_LOGOUT_FAILURE });

const registerSuccess = (_data) => ({ type: AUTH_REGISTER_SUCCESS });
const registerFailure = (error) => ({ type: AUTH_REGISTER_FAILURE, error });

const forgotPasswordSuccess = (_data) => ({ type: FORGOT_PASSWORD_SUCCESS });
const forgotPasswordFailure = (error) => ({
  type: FORGOT_PASSWORD_FAILURE,
  error,
});

const resetPasswordSuccess = (data) => ({ type: RESET_PASSWORD_SUCCESS, data });
const resetPasswordFailure = (error) => ({
  type: RESET_PASSWORD_FAILURE,
  error,
});

const betaSignupSuccess = (data) => ({ type: BETA_SIGNUP_SUCCESS, data });
const betaSignupFailure = (error) => ({ type: BETA_SIGNUP_FAILURE, error });

const subscribeSuccess = (data) => ({ type: AUTH_SUBSCRIBE_SUCCESS, data });
const subscribeFailure = (error) => ({ type: AUTH_SUBSCRIBE_FAILURE, error });

const fetchInvoicesSuccess = (data) => ({ type: AUTH_INVOICES_SUCCESS, data });
const fetchInvoicesFailure = (error) => ({
  type: AUTH_INVOICES_FAILURE,
  error,
});

const updatePasswordSuccess = (data) => ({
  type: UPDATE_PASSWORD_SUCCESS,
  data,
});
const updatePasswordFailure = (error) => ({
  type: UPDATE_PASSWORD_FAILURE,
  error,
});

const updateSettingsSuccess = (data) => ({
  type: UPDATE_SETTINGS_SUCCESS,
  data,
});
const updateSettingsFailure = (error) => ({
  type: UPDATE_SETTINGS_FAILURE,
  error,
});

// const subscribeUpdateSuccess = (data) => ({ type: SUBSCRIBE_UPDATE_SUCCESS, data });
const subscribeUpdateFailure = (error) => ({
  type: SUBSCRIBE_UPDATE_FAILURE,
  error,
});

export const updateCanEditTemplates = (data) => ({
  type: UPDATE_CAN_EDIT_TEMPLATES,
  data,
});

const envUpdateSuccess = (data) => ({ type: UPDATE_ENV, data });

const userFetchSuccess = (data) => ({ type: USER_FETCH_SUCCESS, data });
const userFetchFailure = (error) => ({ type: USER_FETCH_FAILURE, error });

const userAccountActivationSuccess = (data) => ({
  type: USER_ACCOUNT_ACTIVATION_SUCCESS,
  data,
});
const userAccountActivationFailure = (error) => ({
  type: USER_ACCOUNT_ACTIVATION_FAILURE,
  error,
});

const inProgress = () => ({ type: AUTH_IN_PROGRESS });

const uploadFileProgress = (progress) => ({
  type: UPLOAD_FILE_PROGRESS,
  progress,
});
const uploadFileSuccess = (file) => ({ type: UPLOAD_FILE_SUCCESS, file });

export const uploadFileFailure = (error) => ({
  type: UPLOAD_FILE_FAILURE,
  error: {
    message: error.message,
    code: error.code,
    response: error.response
      ? {
          status: error.response.status,
          statusText: error.response.statusText,
          data: error.response.data,
        }
      : null,
  },
});

export function subscribe(stripeToken, plan) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);

    dispatch(inProgress());
    return client
      .post("/api/v1/subscriptions", { subscription: { stripeToken, plan } })
      .then((response) => {
        dispatch(subscribeSuccess(response.data));
      })
      .catch((error) => {
        dispatch(subscribeFailure(getErrorMessage(error)));
      });
  };
}

export function uploadFile(file) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const fileMetadata = {
      objectName: file?.name,
      contentType: file?.type,
      size: file?.size,
    };
    // console.log("File Data", file);

    return client
      .get("/api/v1/uploads/sign", {
        params: {
          objectName: fileMetadata.objectName,
          contentType: fileMetadata.contentType,
          size: fileMetadata.size,
        },
      })
      .then((response) => {
        const { signedUrl, filePath } = response.data;
        // console.log("response data inside file upload", response.data);

        const formData = new FormData();
        formData.append("file", file);

        return fetch(signedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
            "x-amz-acl": "public-read", // Ensure you have this header if required
          },
          body: file,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Upload failed");
            }
            dispatch(uploadFileSuccess(filePath));
            return filePath;
          })
          .catch((error) => {
            dispatch(uploadFileFailure(error));
          });
      })
      .catch((error) => {
        dispatch(uploadFileFailure(error));
      });
  };
}

export function betaSignup(email, volume) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);

    return client
      .post("/api/v1/interested_users", { email, volume })
      .then((response) => {
        dispatch(betaSignupSuccess());
      })
      .catch((error) => {
        dispatch(betaSignupFailure(getErrorMessage(error)));
      });
  };
}

export function login(
  email,
  password,
  navigate,
  { redirect = "/dashboard" } = {},
  setLoader
) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    setLoader(true);
    return client
      .post("/api/v1/sessions", { auth: { email, password } })
      .then((response) => {
        // console.log("response=", response);
        dispatch(loginSuccess(response.data));
        // dispatch(push(redirect));
        // console.log("inside login api=", response.data.auth);
        dispatch(envUpdateSuccess(response.data.auth));

        // console.log(response.data.jwt, process.env.REACT_APP_TOKEN_DOMAIN)

        Cookies.set(AUTH_TOKEN, response.data.jwt, {
          expires: 1,
          domain: process.env.REACT_APP_TOKEN_DOMAIN || ".sendtric.com",
          samesite: "None",
          secure: true,
          path: "/",
        });

        navigate(redirect);
        setLoader(false);
      })
      .catch((error) => {
        // Just to comment
        dispatch(loginFailure(getErrorMessage(error)));
        setLoader(false);
      });
  };
}

export function getUserProfile(
  authToken,
  navigate,
  setPageLoader,
  { redirect = "/dashboard" } = {}
) {
  return (dispatch, getState) => {
    const apiUrl = process.env.REACT_APP_DOMAIN_URL;
    setPageLoader(true);
    return axios
      .get(apiUrl + "/api/v1/user_profile?token=" + authToken)
      .then((response) => {
        // console.log(response)
        dispatch(loginSuccess(response.data));
        dispatch(envUpdateSuccess(response.data.auth));
        Cookies.set(AUTH_TOKEN, response.data.jwt, {
          expires: 1,
          domain: process.env.REACT_APP_TOKEN_DOMAIN || ".sendtric.com",
          samesite: "None",
          secure: true,
          path: "/",
        });
        setPageLoader(false);
        navigate(redirect);
      })
      .catch((error) => {
        dispatch(loginFailure(getErrorMessage(error)));
        setPageLoader(false);
      });
  };
}

export function logout(navigate, { redirect = "/login" } = {}) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);

    return client
      .delete("/api/v1/sessions")
      .then((_response) => {
        dispatch(logoutSuccess());
        // dispatch(push(redirect));
        navigate(redirect);
      })
      .catch((error) => {
        dispatch(logoutFailure(getErrorMessage(error)));
      });
  };
}

export function register(
  email,
  password,
  api_plan_id,
  navigate,
  { redirect = "/register/success" },
  setLoader
) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    setLoader(true);
    return client
      .post("/api/v1/users", { user: { email, password, api_plan_id } })
      .then((response) => {
        dispatch(registerSuccess());
        navigate(redirect);
        setLoader(false);
      })
      .catch((error) => {
        dispatch(registerFailure(getErrorMessage(error)));
        setLoader(false);
      });
  };
}

export function forgotPassword(email) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    return client
      .post("/api/v1/password_reset_requests", { email: email })
      .then((response) => {
        dispatch(forgotPasswordSuccess());
      })
      .catch((error) => {
        dispatch(forgotPasswordFailure(getErrorMessage(error)));
      });
  };
}

// export function resetPassword(email, resetId, password, passwordConfirmation, navigate,{redirect='/dashboard'}) {
//   return (dispatch, getState) => {
//     const client = generateApiClient(getState);
//     const params = { password, password_confirmation: passwordConfirmation, email };

//     return client
//       .put("/api/v1/password_reset_requests/" + resetId, params)
//       .then((response) => {
//         dispatch(resetPasswordSuccess(response.data));
//         navigate(redirect);
//       })
//       .catch((error) => {
//         dispatch(resetPasswordFailure(getErrorMessage(error)));
//       });
//   };
// }

export function resetPassword(
  email,
  resetId,
  password,
  passwordConfirmation,
  navigate,
  setIsPending,
  { redirect = "/dashboard" }
) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = {
      password,
      password_confirmation: passwordConfirmation,
      email,
    };

    setIsPending(true);
    return client
      .put("/api/v1/password_reset_requests/" + resetId, params)
      .then((response) => {
        // console.log("Password reset successful:", response.data);
        // dispatch(userFetchSuccess(response.data));
        dispatch(resetPasswordSuccess(response.data));
        navigate(redirect);
        setIsPending(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        console.error("Password reset failed:", errorMessage);
        dispatch(resetPasswordFailure(errorMessage));
        setIsPending(false);
      });
  };
}

export function fetchInvoices(email) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);

    return client
      .get("/api/v1/invoices")
      .then((response) => {
        dispatch(fetchInvoicesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(fetchInvoicesFailure(getErrorMessage(error)));
      });
  };
}

export function updatePassword(password) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    const params = { password, password_confirmation: password };

    return client
      .post("/api/v1/password_update", params)
      .then((response) => {
        dispatch(updatePasswordSuccess(response.data));
        window.location.reload(false);
      })
      .catch((error) => {
        dispatch(updatePasswordFailure(getErrorMessage(error)));
      });
  };
}

export function updateSettings(status) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);
    return client
      .post("/api/v1/update_edit_templates", { status: status })
      .then((response) => {
        dispatch(updateSettingsSuccess(response.data));
        dispatch(updateCanEditTemplates(status));
      })
      .catch((error) => {
        dispatch(updateSettingsFailure(error));
      });
  };
}

export function createStripeCheckoutSession(plan) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);

    dispatch(inProgress()); // Assuming you have an action for indicating progress

    return client
      .post("/api/v1/subscriptions/create_checkout_session", {
        subscription: { plan },
      })
      .then((response) => {
        window.location.href = response.data.session_url; // Example
      })
      .catch((error) => {
        dispatch(subscribeUpdateFailure(getErrorMessage(error)));
      });
  };
}

export function activateAccount(
  token,
  email,
  navigate,
  { redirect = "/dashboard" } = {}
) {
  return (dispatch, getState) => {
    // const client = generateApiClient(getState);
    const apiUrl = process.env.REACT_APP_DOMAIN_URL;
    return axios
      .post(apiUrl + "/api/v1/activate", {
        auth: { token, email },
        email,
        token,
      })
      .then((response) => {
        dispatch(userAccountActivationSuccess(response.data));
        Cookies.set(AUTH_TOKEN, response.data.jwt, {
          expires: 1,
          domain: process.env.REACT_APP_TOKEN_DOMAIN || ".sendtric.com",
          samesite: "None",
          secure: true,
          path: "/",
        });
        navigate(redirect);
      })
      .catch((error) => {
        dispatch(userAccountActivationFailure(getErrorMessage(error)));
      });
  };
}

export function updateUser(navigate, { redirect = "/dashboard" } = {}) {
  return (dispatch, getState) => {
    const client = generateApiClient(getState);

    return client
      .get("/api/v1/user_data")
      .then((response) => {
        if (window && window.usermaven && response) {
          window.usermaven("track", "subscription_payment", {
            user_email: response?.data?.email,
            user_id: response?.data?.id,
            subscription_name: response?.data?.tier,
            price: (response?.data?.subscription?.price)/100,
          });
        }
        dispatch(userFetchSuccess(response.data));
        navigate(redirect);
      })
      .catch((error) => {
        dispatch(userFetchFailure(getErrorMessage(error)));
      });
  };
}

export const clearAuthError = () => ({
  type: CLEAR_AUTH_ERROR,
});
