import React from "react";
import _ from "lodash";

import {
  TOAST_DISMISSED,
  USER_ACTIVATION_SUCCESS,
  USER_ACTIVATION_FAILURE,
  COOKIE_NOTIFICATION_PROMPTED,
} from "../constants/toastConstants";

import {
  AUTH_REGISTER_SUCCESS,
  FORGOT_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  BETA_SIGNUP_SUCCESS,
  BETA_SIGNUP_FAILURE,
  AUTH_SUBSCRIBE_FAILURE,
  AUTH_SUBSCRIBE_SUCCESS,
  AUTH_INVOICES_FAILURE,
  AUTH_INVOICES_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
} from "../constants/authenticationConstants";

import {
  TEMPLATE_FETCH_FAILURE,
  TEMPLATE_SAVE_FAILURE,
  TEMPLATE_DESTROY_FAILURE,
  TEMPLATE_SAVE_SUCCESS,
} from "../constants/templateConstants";

import {
  TIMER_FETCH_FAILURE,
  TIMER_SAVE_FAILURE,
  TIMER_ARCHIVE_FAILURE,
  TIMER_SAVE_SUCCESS,
} from "../constants/timerConstants";

import { UPLOAD_FAILURE } from "../constants/uploadConstants";

import {
  SUBACCOUNT_NAME_UPDATE_FAILURE,
  SUBACCOUNT_NAME_UPDATE_SUCCESS,
} from "../constants/subaccountConstants";
import {
  CUSTOM_FONT_DELETE_FAILURE,
  CUSTOM_FONT_SAVE_FAILURE,
} from "../constants/customFontConstants";

export const initialState = {
  active: [],
};

const addToast = (currentToasts, newToast) => {
  const updatedToasts = _.cloneDeep(currentToasts);
  updatedToasts.push(newToast);
  return updatedToasts;
};

const removeToast = (currentToasts, toastId) => {
  // console.log(currentToasts, toastId);
  const updatedToasts = _.cloneDeep(currentToasts);
  _.remove(updatedToasts, (toast) => {
    return toast.id === toastId;
  });

  return updatedToasts;
};

export default (state = initialState, action) => {
  let updatedToasts;

  switch (action.type) {
    case FORGOT_PASSWORD_SUCCESS:
      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "success",
        header: "Reset Request Successful",
        message: "Please check your email for next steps.",
      });
      return { active: updatedToasts };
    case USER_ACTIVATION_SUCCESS:
      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "success",
        header: "Account Activated!",
        message: "Thank you.Your account is now active.",
      });
      return { active: updatedToasts };
    case AUTH_SUBSCRIBE_FAILURE:
      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "error",
        header: "Error Processing Payment",
        message: action.error,
      });
      return { active: updatedToasts };
    case AUTH_SUBSCRIBE_SUCCESS:
      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "success",
        header: "Subscription Processed!",
        message: "Thank you for choosing Sendtric!",
      });
      return { active: updatedToasts };
    case AUTH_INVOICES_FAILURE:
      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "error",
        header: "Error Loading Invoices!",
        message: action.error,
      });
      return { active: updatedToasts };
    case TEMPLATE_SAVE_SUCCESS:
    case TIMER_SAVE_SUCCESS:
      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "success",
        header: "Successfully updated!",
        message: "Your changes have been saved.",
      });
      return { active: updatedToasts };
    case USER_ACTIVATION_FAILURE:
      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "error",
        header: "Activation Failure",
        message: "This activation link is invalid or expired.",
      });
      return { active: updatedToasts };
    case RESET_PASSWORD_FAILURE:
      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "error",
        header: "Reset Password Failure",
        message: "This reset link is invalid or expired.",
      });
      return { active: updatedToasts };
    case UPDATE_PASSWORD_SUCCESS:
      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "success",
        header: "Password updated",
        message: "Password Successfully updated.",
      });
      return { active: updatedToasts };
    case SUBACCOUNT_NAME_UPDATE_FAILURE:
      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "error",
        header: "Sub Account Name Failure",
        message: "Name Already Exist.",
      });
      return { active: updatedToasts };
    case SUBACCOUNT_NAME_UPDATE_SUCCESS:
      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "success",
        header: "Sub Account Successful",
        message: "Name successful update.",
      });
      return { active: updatedToasts };
    case TIMER_FETCH_FAILURE:
    case TIMER_SAVE_FAILURE:
    case TIMER_ARCHIVE_FAILURE:
    case TEMPLATE_FETCH_FAILURE:
    case TEMPLATE_SAVE_FAILURE:
    case TEMPLATE_DESTROY_FAILURE:
    case UPLOAD_FAILURE:
    case CUSTOM_FONT_DELETE_FAILURE:
      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "error",
        header: "Error Occurred",
        message:
          action.message ||
          action.error ||
          "Please try again or contact support.",
      });
      return { active: updatedToasts };
    case CUSTOM_FONT_SAVE_FAILURE:
      let errorMessage =
        action.message ||
        action.error ||
        "Please try again or contact support.";
      let htmlMessage = (
        <>
          <div>{errorMessage}</div>
          <a
            href="https://www.sendtric.com/resolving-the-failed-to-render-header/"
            target="_blank"
          >
            Click here for learn more.
          </a>
        </>
      );

      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "error",
        header: "Error Occurred",
        message: htmlMessage,
        autoDismiss: false,
      });
      return { active: updatedToasts };
    case BETA_SIGNUP_SUCCESS:
      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "success",
        header: "Successfully signed up!",
        message: "We will notify you of openings via email.",
      });
      return { active: updatedToasts };
    case TOAST_DISMISSED:
      updatedToasts = removeToast(state.active, action.data.id);
      return { active: updatedToasts };
    case COOKIE_NOTIFICATION_PROMPTED:
      const message = (
        <span>
          This website uses cookies to ensure you get the best experience on our
          website. <a href="/legal/cookies.pdf">Learn more.</a>&nbsp;
        </span>
      );

      updatedToasts = addToast(state.active, {
        id: _.uniqueId(),
        type: "info",
        header: null,
        message,
        autoDismiss: false,
      });

      return { active: updatedToasts };
    default:
      return state;
  }
};
