import PropTypes from "prop-types";
import React from "react";

import { Button, Form, Input, Message } from "semantic-ui-react";
import css from "../../assets/styles/shared/forms.module.scss";
import { ClipLoader } from "react-spinners";

const { func, string } = PropTypes;

class ResetPassword extends React.Component {
  static propTypes = {
    resetPassword: func.isRequired,
    error: string,
  };

  state = {
    password: "",
    passwordConfirmation: "",
  };

  render() {
    return (
      <Form
        className={css.loginForm}
        onSubmit={this.handleSubmit}
        error={!!this.props.error}
      >
        <h1>Reset Password</h1>
        {this.renderError()}
        <Form.Input
          label="Password"
          type="password"
          icon="lock"
          iconPosition="left"
          onChange={this.handleChangeCurry("password")}
        />
        <Form.Input
          label="Password Confirmation"
          type="password"
          icon="lock"
          iconPosition="left"
          onChange={this.handleChangeCurry("passwordConfirmation")}
        />
        <Form.Button
          primary
          disabled={this.props.isPending}
          content={
            this.props.isPending ? (
              <div style={{ display: "flex", gap: "3px" }}>
                Submit <ClipLoader color="#fff" size={15} />
              </div>
            ) : (
              "Submit"
            )
          }
        />
      </Form>
    );
  }

  renderError = () => {
    return (
      <Message error>
        <Message.Header>Reset Error</Message.Header>
        <p>{this.props.error}</p>
      </Message>
    );
  };

  registerRef = (name) => {
    return (el) => (this[name] = el);
  };

  handleChangeCurry = (name) => {
    return (event) => this.setState({ [name]: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // console.log("this.state inside reset=", this.state);
    this.props.resetPassword(
      this.state.password,
      this.state.passwordConfirmation
    );
  };
}

export default ResetPassword;
