import PropTypes from "prop-types";
import React from "react";
import _, { filter } from "lodash";
import { Link, useNavigate } from "react-router-dom";
import Clipboard from "react-clipboard.js";
import {
  Card,
  Statistic,
  Message,
  Container,
  Icon,
  Table,
  Input,
  Button,
  Popup,
  Progress,
} from "semantic-ui-react";
import CreateTimerButtonContainer from "../../containers/createTimerButtonContainer";
import BillingContainer from "../../containers/billingContainer";

import css from "../../assets/styles/dashboard/index.module.scss";
import timerCss from "../../assets/styles/timerList/index.module.scss";

import { createBrowserHistory } from "history";

import tableCss from "../../assets/styles/shared/tables.module.scss";
// import gridCss from "../../assets/styles/shared/grids.scss";
import guideCss from "../../assets/styles/shared/guide.module.scss";

import {
  BlueCheckIconComponent,
  DeleteIconComponent,
  EditIconComponent,
  EmptyCheckIconComponent,
  ExternalLinkIconComponent,
  WhiteCopyIconComponent,
} from "../iconComponents";

import moment from "moment-timezone";

import timerHelper from "../../helpers/timers/timerHelper";
import TimerPreview from "../shared/timerPreview";

const { number, object, bool, func, array } = PropTypes;
const browserHistory = createBrowserHistory();

const initialState = {
  searchSubaccountText: "",
  subaccountName: "",
  stepCount: 1,
  doLaterStatus: false,
  showGuideStatus: false,
};

class DashboardPage extends React.Component {
  static propTypes = {
    activeTimers: number,
    totalLoads: number,
    envContext: object,
    fetch: func,
    subscribe: func,
    user: object,
    freeOverrage: bool,
    corporateSubaccounts: array,
    timers: array,
    subaccountDetails: object,
  };

  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  componentDidMount() {
    const subaccount_id = this.props.user.subaccount_id || 0;
    this.props.fetch(subaccount_id);
    if (this.isCorporateUser()) {
      this.props.fetchCorporateSubaccount();
    } else if (this.isSubaccountUser()) {
      this.props.fetchSubaccountLatestTimer(subaccount_id);
      this.props.fetchSubaccountDetails(subaccount_id);
    } else {
      this.initiateTheStep();
    }

    if (this.props.user && this.props.user.stripe_id) {
      if (window["callProfitwellStart"]) {
        window["callProfitwellStart"](this.props.user.stripe_id);
      }
    }
  }

  initiateTheStep = () => {
    if (this.isAPIUser()) {
      if (this.props.user.step_count == 0) {
        this.props.updateStepCount(1);
        this.setState({ stepCount: 1 });
      } else {
        this.setState({ stepCount: this.props.user.step_count });
        if (this.props.user.step_count < 0) {
          this.setState({ doLaterStatus: true });
        }
      }
    }
  };

  static getDerivedStateFromProps(props, current_state) {
    let changed = false;
    let newStates = { ...current_state };

    if (props.subaccountDetails && props.subaccountDetails.subaccount) {
      newStates.subaccountName = props.subaccountDetails.subaccount.name;
      changed = true;
    }
    if (changed) {
      return newStates;
    }
    return null;
  }

  isCorporateUser() {
    const { user } = this.props;
    return (
      user.is_company ||
      (user.features && user.features.subaccount && user.parent_id === 0)
    );
  }

  // isCorporateUser() {
  //   return this.props.user.is_company || (this.props.user.features.subaccount && this.props.user.parent_id == 0);
  // }

  // isCorporateUser() {
  //   return this.props.user.is_company || (this.props.user.parent_id == 0);
  // }

  isSubaccountUser() {
    return this.props.user.subaccount_id != 0;
  }

  hasActiveSubscription = () => {
    return this.props.user.subscription !== null;
  };

  isAPIUser() {
    return this.props.user.is_api_plans;
  }

  renderActiveTimers = () => {
    let totalTimers = this.props.activeTimers;
    // if (this.isSubaccountUser()) {
    //   if (this.props.timers) {
    //     let length = this.props.timers.length;
    //     if (length > 0) {
    //       let counts = _.countBy(this.props.timers, (timer) => {
    //         return (
    //           timer.subaccount_id == this.props.user.subaccount_id &&
    //           !timer.archived
    //         );
    //       });

    //       totalTimers = counts[true] ? counts[true] : 0;
    //     } else {
    //       totalTimers = 0;
    //     }
    //   }
    // }

    return (
      // <Card color="red" className={css.card}>
      <Card className={css.card_background}>
        <Card.Content>
          <Statistic value={totalTimers} label="Active Timers" />
        </Card.Content>
      </Card>
    );
  };

  renderAllTimeViews = () => {
    let allTimeViews = this.props.totalLoads;
    return (
      <Card className={css.card_background}>
        <Card.Content>
          <Statistic
            value={allTimeViews.toLocaleString()}
            label="Total views"
          />
        </Card.Content>
      </Card>
    );
  };

  renderCycleViews = () => {
    let views = _.get(this.props, "user.subscription.views", 0);
    if (this.isSubaccountUser()) {
      views = this.props.cycleViews;
    }
    return (
      <Card className={css.card_background}>
        <Card.Content>
          <Statistic
            value={views.toLocaleString()}
            label="Views (this billing cycle)"
          />
        </Card.Content>
      </Card>
    );
  };

  renderFreeWarning = () => {
    if (this.props.freeOverrage && !this.hasActiveSubscription()) {
      const content = (
        <span>Please purchase a plan to prevent service interruption.</span>
      );
      return (
        <Message
          warning
          header="One of your free timers has exceeded our view limit"
          content={content}
        />
      );
    }
  };

  renderViewWarning = () => {
    const currentViews = _.get(this.props, "user.subscription.views", 0);
    const planAllowance = _.get(
      this.props,
      "user.subscription.view_allowance",
      0
    );

    if (currentViews > planAllowance) {
      const content = (
        <span>
          Please <Link to="/settings">upgrade your plan</Link> to prevent
          service interruption.
        </span>
      );
      return (
        <Message
          warning
          header="You have exceeded your monthly view allowance"
          content={content}
        />
      );
    }
  };

  renderCurrentPlan = () => {
    const currentPlan = _.startCase(
      _.get(this.props, "user.subscription.nickname", "unknown")
    );
    let planInterval = _.get(this.props, "user.subscription.interval", "month");
    // Like toLocaleString but cross-browser. Without this server rendering was different causing page reload
    const planAllowance = _.get(
      this.props,
      "user.subscription.view_allowance",
      0
    )
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    const subaccountFeature = _.get(
      this.props,
      "user.features.subaccount",
      false
    );

    return (
      <h4>
        {subaccountFeature && this.isCorporateUser() && (
          <>
            Corporate account
            <br />
          </>
        )}
        {subaccountFeature && this.isSubaccountUser() && (
          <>
            {this.state.subaccountName}
            <br />
          </>
        )}
        Current plan: {currentPlan} ({planAllowance} views per {planInterval})
      </h4>
    );
  };

  renderDashboardStats = () => {
    if (this.props.user.tier === "trial") {
      return null;
    }

    return (
      <span>
        {this.renderViewWarning()}
        <h2>Dashboard</h2>
        {this.renderCurrentPlan()}
        <div className={css.statContainer}>
          <Card.Group itemsPerRow={3}>
            {this.renderActiveTimers()}
            {this.renderAllTimeViews()}
            {this.renderCycleViews()}
          </Card.Group>
        </div>
      </span>
    );
  };

  renderFreeTrialBanner = () => {
    const trialEnd = _.get(this.props, "user.subscription.trial_end", 0);
    const card = _.get(this.props, "user.subscription.card");
    if (!trialEnd || card) {
      return null;
    }
    return (
      <Message
        warning
        header={
          <div>
            Your free trial ends on {moment(trialEnd * 1000).format("LL")}.
          </div>
        }
        content={
          <div>
            <p>
              You can set payment info in <Link to="/settings">Settings</Link>.
            </p>
          </div>
        }
      />
    );
  };

  renderAnnouncementBanner = () => {
    return (
      <Message icon positive>
        <Icon name="bullhorn" />
        <Message.Content>
          <Message.Header>Share your countdown emails with us!</Message.Header>
          <p>
            We want to share your email designs with the world. Forward your
            emails to{" "}
            <a href="mailto:inspiration@sendtric.com">
              inspiration@sendtric.com
            </a>
            &nbsp; for a chance to be featured in our upcoming blog post.
          </p>
        </Message.Content>
      </Message>
    );
  };

  renderSubaccountDashboard = () => {
    return (
      <>
        <div className={tableCss.filterDiv} style={{ marginTop: "35.5px" }}>
          <div>
            {/* <Input className={gridCss.gridSearchText} icon iconPosition='left'  placeholder='Search for an account'>
                <input value={this.state.searchSubaccountText} onChange={this.searchSubaccountTextHandler}/> 
                <Icon name='search' />
              </Input> */}
            <h2>Recently created timers</h2>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link to="/timers" className={css.seeAllButton}>
              See all
            </Link>
          </div>
        </div>
        {this.renderSubaccountDashboardTableContents()}
      </>
    );
  };

  renderSubaccountDashboardTableTemplates = (timers) => {
    return _.map(timers, (timer) => {
      let ends_at = new Date(timer.ends_at);
      let endsAt = moment(ends_at).tz(timer.time_zone);
      const isExpired =
        endsAt.isBefore(moment()) &&
        !timer.archived &&
        timer.timer_type === "standard";
      endsAt = this.formatDate(endsAt);
      let createdAt = moment(timer.created_at).tz(timer.time_zone);
      createdAt = this.format24HoursDate(createdAt);
      const timerUrl = timer.url;

      return (
        <Table.Row
          className={isExpired ? timerCss.expired : ""}
          key={"subaccount_timer_" + timer.timer_id}
        >
          <Table.Cell className={tableCss.name}>
            {timer.name}
            <Popup
              basic
              position="right center"
              trigger={<Icon name="eye" />}
              content={
                <TimerPreview
                  type="Timer"
                  timer={timer}
                  genUrl={this.props.envContext.gen_url}
                />
              }
            />
          </Table.Cell>
          <Table.Cell>{createdAt}</Table.Cell>
          <Table.Cell>{this.renderEndDate(timer)}</Table.Cell>
          <Table.Cell>{timer.total_views}</Table.Cell>
          <Table.Cell>{timer.paid_views}</Table.Cell>
          <Table.Cell className={tableCss.actionDiv}>
            {this.renderEditIcon(timer.archived, timer)}
            {this.renderArchiveIcon(timer.archived, timer.timer_id)}
            {this.renderCopyLink(timerUrl)}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  formatDate = (date) => {
    return moment(date).format("MMM DD, YYYY hh:mm A");
  };

  format24HoursDate = (date) => {
    return moment(date).format("MMM DD, YYYY HH:mm");
  };

  renderEndDate = (timer) => {
    if (timer.timer_type === "standard") {
      let endsAt = moment.utc(timer.ends_at).tz(timer.time_zone);
      if (!endsAt.isValid()) {
        endsAt = moment(timer.ends_at + " +GMT0000").tz(timer.time_zone);
        return this.formatDate(endsAt);
      } else {
        return this.formatDate(endsAt);
      }
    } else if (timer.timer_type === "perpetual") {
      const durationObject = timerHelper.getDurationObject(timer.duration);
      const { days, hours, minutes, seconds } = durationObject;
      return `Perpetual (${days}d ${hours}h ${minutes}m)`;
    } else if (timer.timer_type === "dynamic") {
      return "(Dynamic Timer)";
    } else {
      return null;
    }
  };

  renderEditIcon = (archived, timer) => {
    const { user } = this.props;

    if (archived) {
      return null;
    }

    return (
      <Popup
        inverted
        // trigger={<Icon name="edit" onClick={this.handleEdit(timer.id)} />}
        trigger={
          <span
            style={{ cursor: "pointer" }}
            onClick={this.handleEdit(timer.timer_id)}
          >
            <EditIconComponent />
          </span>
        }
        content={"Edit"}
        position={"top center"}
      />
    );
  };

  renderArchiveIcon = (archived, id) => {
    const info = archived ? "Unarchive" : "Archive";

    return (
      <Popup
        inverted
        // trigger={<Icon name="archive" onClick={this.handleArchive(id, archived)} />}
        trigger={
          <span
            style={{ cursor: "pointer" }}
            name="trash"
            onClick={this.handleArchive(id, archived)}
          >
            <DeleteIconComponent />
          </span>
        }
        content={info}
        position={"top center"}
      />
    );
  };

  renderCopyLink(url) {
    if (url) {
      const copyButton = (
        <Clipboard component="span" data-clipboard-text={url}>
          <Icon name="linkify" className={tableCss.linkIcon} />
        </Clipboard>
      );

      return (
        <Popup
          inverted
          trigger={copyButton}
          content="Copied!"
          position="top center"
          hideOnScroll
        />
      );
    } else {
      return (
        <Popup
          inverted
          content="Upgrade your plan to use this timer"
          position="top center"
          hideOnScroll
          trigger={<Icon name="linkify" disabled />}
        />
      );
    }
  }

  handleArchive = (timerId, archived) => {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.props.archive(timerId, !archived);
    };
  };

  handleEdit = (timerId) => {
    // console.log(timerId, "timerId");
    return () => {
      this.props.navigate("/timer/" + timerId);
    };
  };

  renderZeroState = () => {
    return (
      <div className={tableCss.zero}>
        You do not currently have any active timers.
      </div>
    );
  };

  renderSubaccountDashboardTableContents = () => {
    // let filteredTimers = _.orderBy(
    //   this.props.timers,
    //   ["created_at", "updated_at"],
    //   ["desc", "desc"]
    // );
    // filteredTimers = _.filter(filteredTimers, (timer) => {
    //   if (this.isSubaccountUser()) {
    //     return (
    //       !timer.archived &&
    //       this.props.user.subaccount_id == timer.subaccount_id
    //     );
    //   } else {
    //     return !timer.archived;
    //   }
    // });
    if (_.isEmpty(this.props.subaccountLatestTimer)) {
      return this.renderZeroState();
    } else {
      // let total = filteredTimers.length;
      // filteredTimers = filteredTimers.slice(0, 5);
      return (
        <Table basic className={tableCss.tableBorder}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Creation date</Table.HeaderCell>
              <Table.HeaderCell>End date</Table.HeaderCell>
              <Table.HeaderCell>Active views</Table.HeaderCell>
              <Table.HeaderCell>
                Views{" "}
                <span className={tableCss.secondary}>(this billing cycle)</span>
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row className={tableCss.tableRowBackground}>
              <Table.HeaderCell className={tableCss.tableCol} colSpan={"6"}>
                {this.props.activeTimers} total timers
              </Table.HeaderCell>
            </Table.Row>
            {this.renderSubaccountDashboardTableTemplates(this.props.subaccountLatestTimer)}
          </Table.Body>
        </Table>
      );
    }
  };

  doItLater = () => {
    if (this.state.showGuideStatus) {
      this.setState({ showGuideStatus: false });
    } else {
      let stepCountValue = this.state.stepCount * -1;
      this.setState({ stepCount: stepCountValue });
      this.setState({ doItLater: true });
      this.props.updateStepCount(stepCountValue);
    }
  };

  // Show Setup Guide
  showSetupGuide = () => {
    this.setState({ showGuideStatus: true });
  };

  renderDoItLaterFlag = () => {
    if (!this.isAPIUser()) {
      return <></>;
    }

    if (this.state.stepCount >= 1) {
      return <></>;
    }

    return (
      <div
        style={{
          position: "fixed",
          bottom: "150px",
          right: "100px",
          cursor: "pointer",
        }}
        onClick={this.showSetupGuide}
      >
        <img src="/flag.png" title="Setup guide" />
      </div>
    );
  };

  updateCopyStep = () => {
    if (this.state.stepCount == 1) {
      this.setState({ stepCount: 2 });
      this.props.updateStepCount(2);
    } else if (this.state.stepCount == -1) {
      this.setState({ stepCount: -2 });
      this.props.updateStepCount(-2);
    }
  };

  completeTheStep = () => {
    if (this.state.stepCount == 2 || this.state.stepCount == 1) {
      this.setState({ stepCount: 3 });
      this.props.updateStepCount(3);
    } else if (this.state.stepCount == -1 || this.state.stepCount == -2) {
      this.setState({ stepCount: -3 });
      this.props.updateStepCount(-3);
    }
  };

  renderSetupGuide() {
    const copyButton = (
      <Clipboard
        component="div"
        data-clipboard-text={this.props.user.api_token}
        style={{
          background: "#5DC2FA",
          borderRadius: "6px",
          padding: "12px 24px",
          height: "32px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          cursor: "pointer",
        }}
        onClick={this.updateCopyStep}
      >
        <span style={{ paddingTop: "4px" }}>
          <WhiteCopyIconComponent />
        </span>
        <span
          style={{
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "17px",
            color: "#FFFFFF",
          }}
        >
          Copy
        </span>
      </Clipboard>
    );

    const stepOneComplete = true;
    const stepTwoComplete =
      this.state.stepCount == 2 ||
      this.state.stepCount == 3 ||
      this.state.stepCount == -2 ||
      this.state.stepCount == -3;
    const stepThreeComplete =
      this.state.stepCount == 3 || this.state.stepCount == -3;

    let step = this.state.stepCount;
    if (step < 0) {
      step = step * -1;
    }

    return (
      <div>
        {/* row 1 */}
        <div className={guideCss.row1}>
          <div className={guideCss.setupTitle}>Setup Guide</div>
          <div className={guideCss.linkStyle} onClick={this.doItLater}>
            I’ll do it later
          </div>
        </div>
        {/* row 2 */}
        <div className={guideCss.row2}>
          <div>{step} of 3 steps completed</div>
          <div style={{ flexGrow: "1", height: "7px" }}>
            <Progress
              size="tiny"
              value={step}
              total={3}
              color="green"
              style={{ marginBottom: "0px" }}
            />
          </div>
        </div>
        {/* row 3 */}
        <div className={guideCss.row3}>
          <div>
            <span>
              {stepOneComplete ? (
                <BlueCheckIconComponent />
              ) : (
                <EmptyCheckIconComponent />
              )}
            </span>
          </div>
          <div>
            <div className={guideCss.rowTitle}>Read documentation:</div>
            <div className={guideCss.row3Description}>
              Read the documentation to understand how to use Sendtric's API
            </div>
            <div
              style={{
                display: "flex",
                gap: "16px",
                border: "1px solid #DCE2E3",
                borderRadius: "6px",
                width: "fit-content",
              }}
            >
              <div style={{ padding: "16px" }}>
                <p
                  style={{
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "16px !important",
                    lineHeight: "19px",
                    color: "#000000",
                  }}
                >
                  Sendtric
                </p>
                <p
                  style={{
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "16px !important",
                    lineHeight: "19px",
                    color: "#606062",
                  }}
                >
                  Sendtric's API allows creation and editing of countdown
                  timers.
                </p>
                <p>
                  <a
                    href="https://documenter.getpostman.com/view/23380037/2s7YtWDtD2"
                    target="_blank"
                    style={{
                      fontFamily: "Lato",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "14px !important",
                      lineHeight: "17px",
                      color: "#000000",
                    }}
                  >
                    https://documenter.getpostman.com/view/23380037/2s7YtWDtD2
                  </a>
                </p>
              </div>
              <div>
                <img src="/postman.png" />
              </div>
            </div>
          </div>
        </div>
        {/* row 4  */}
        <div className={guideCss.row4}>
          <div>
            <span>
              {stepTwoComplete ? (
                <BlueCheckIconComponent />
              ) : (
                <EmptyCheckIconComponent />
              )}
            </span>
          </div>
          <div>
            <div className={guideCss.rowTitle}>Get your API Key</div>
            <div className={guideCss.row3Description}>
              You can always find it later in Settings.
            </div>
            <div
              style={{
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
                color: "#000000",
                marginBottom: "8px",
              }}
            >
              API Key:
            </div>
            <div style={{ display: "flex", gap: "7px" }}>
              <div
                style={{
                  border: "1px solid #DCE2E3",
                  borderRadius: "4px",
                  padding: "8px",
                  height: "32px",
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#000000",
                  background: "#F7F8F8",
                }}
              >
                {this.props.user.api_token}
              </div>
              <Popup
                inverted
                trigger={copyButton}
                content="Copied!"
                position="top center"
                hideOnScroll
              />
            </div>
          </div>
        </div>
        {/* row 5  */}
        <div className={guideCss.row5}>
          <div>
            <span>
              {stepThreeComplete ? (
                <BlueCheckIconComponent />
              ) : (
                <EmptyCheckIconComponent />
              )}
            </span>
          </div>
          <div>
            <div className={guideCss.rowTitle}>Test our API</div>
            <div className={guideCss.rowDescription}>
              Select one of our API calls, connect it to one of your frontend
              elements and use the API key to make the call.
            </div>
            <Button basic color="black" onClick={this.completeTheStep}>
              Mark as complete
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderActive() {
    if (
      (this.isAPIUser() && this.state.stepCount >= 1) ||
      this.state.showGuideStatus
    ) {
      return this.renderSetupGuide();
    }

    return (
      <>
        <div>
          {this.renderAnnouncementBanner()}
          {this.renderFreeTrialBanner()}
          {this.renderDashboardStats()}
          {this.isCorporateUser() && this.renderCorporateSubaccount()}
          {this.isSubaccountUser() && this.renderSubaccountDashboard()}
          <h2>Questions?</h2>
          See the <Link to="/faq">FAQs</Link> or contact support at{" "}
          <a href="mailto:support@sendtric.com">support@sendtric.com</a>.
        </div>
        {this.renderDoItLaterFlag()}
      </>
    );
  }

  searchSubaccountTextHandler = (e) => {
    this.setState({ searchSubaccountText: e.target.value });
  };

  jumpToAccount = () => {};

  renderCorporateSubaccount() {
    return (
      <>
        <div className={tableCss.filterDiv} style={{ marginTop: "35.5px" }}>
          <div>
            {/* <Input className={gridCss.gridSearchText} icon iconPosition='left'  placeholder='Search for an account'>
                <input value={this.state.searchSubaccountText} onChange={this.searchSubaccountTextHandler}/> 
                <Icon name='search' />
              </Input> */}
            <h2>Sub accounts</h2>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link to="/accounts" className={css.seeAllButton}>
              See all
            </Link>
          </div>
        </div>
        {this.renderCorporateSubaccountTableContents()}
      </>
    );
  }

  renderZeroCorporateSubaccountState() {
    return (
      <div className={tableCss.zero}>
        You do not currently have any subaccount users.
      </div>
    );
  }

  jumpToDetails = (subaccountId) => {
    return () => {
      this.props.navigate("/subaccount/" + subaccountId);
    };
  };

  renderCorporateSubaccountTemplates() {
    let filteredSubaccounts = [...this.props.corporateSubaccounts];
    if (filteredSubaccounts.length > 5) {
      filteredSubaccounts = _.slice(filteredSubaccounts, 0, 5);
    }
    return _.map(filteredSubaccounts, (account) => {
      return (
        <Table.Row key={"corporate_subaccount_" + account.id}>
          <Table.Cell className={tableCss.name}>{account.name}</Table.Cell>
          <Table.Cell>{account.total_timer_count}</Table.Cell>
          <Table.Cell>{account.total_views}</Table.Cell>
          <Table.Cell>{account.paid_views}</Table.Cell>
          <Table.Cell className={tableCss.actions}>
            <span
              className={tableCss.pointer}
              onClick={this.jumpToDetails(account.id)}
            >
              <ExternalLinkIconComponent />
            </span>
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  renderCorporateSubaccountTableContents() {
    if (_.isEmpty(this.props.corporateSubaccounts)) {
      return this.renderZeroCorporateSubaccountState();
    } else {
      let totalSubaccount = this.props.corporateSubaccounts.length;
      let totalDisplaySubaccount = 5;
      if (totalDisplaySubaccount > totalSubaccount) {
        totalDisplaySubaccount = totalSubaccount;
      }
      return (
        <Table basic className={tableCss.tableBorder}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Active timers</Table.HeaderCell>
              <Table.HeaderCell>Total views</Table.HeaderCell>
              <Table.HeaderCell>
                Views
                <span className={tableCss.secondary}>(this billing cycle)</span>
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row className={tableCss.tableRowBackground}>
              <Table.HeaderCell className={tableCss.tableCol} colSpan={"5"}>
                Shown {totalDisplaySubaccount} out of {totalSubaccount}
              </Table.HeaderCell>
            </Table.Row>
            {this.renderCorporateSubaccountTemplates()}
          </Table.Body>
          {/* <Table.Footer style={{textAlign:'right'}}>
              <Table.Row>
                  <Table.HeaderCell colSpan='3'>
                      <Pagination  defaultActivePage={this.state.corporateActivePage} onPageChange={this.handleCorporatePaginationChange} totalPages={this.state.totalCorporatePages} />
                  </Table.HeaderCell>
              </Table.Row>
          </Table.Footer> */}
        </Table>
      );
    }
  }

  renderInactive() {
    return (
      <>
        {this.renderFreeWarning()}
        <Container text textAlign="center">
          <div className={css.inactiveCtaContainer}>
            <h2 className={css.inactiveCta}>Want to try before you buy?</h2>
            <CreateTimerButtonContainer size="huge">
              <Icon name="plus" />
              Create a Timer
            </CreateTimerButtonContainer>
          </div>
        </Container>
        <BillingContainer />
      </>
    );
  }

  render() {
    return this.hasActiveSubscription()
      ? this.renderActive()
      : this.renderInactive();
  }
}

const Dashboard = (props) => {
  const navigate = useNavigate();
  return <DashboardPage {...props} navigate={navigate} />;
};

export default Dashboard;
