import React, { useState } from "react";

const RaiseError = () => {
  const [state, setState] = useState(null);

  // This will cause a runtime error when the component renders
  const throwError = () => {
    // Attempt to access a property of `null` will throw an error
    return state.nonExistentProperty.toString();
  };

  return (
    <div>
      <h1>Intentional Runtime Error Example</h1>
      {/* The error will occur here */}
      {throwError()}
    </div>
  );
};

export default RaiseError;