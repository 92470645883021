// import React from "react";
// import { Provider } from "react-redux";
// import { BrowserRouter} from "react-router-dom";
// import { createBrowserHistory } from 'history';

// import CreateRoutes from "../routes/CreateRoutes";
// import reducers from "../reducers/index";
// import { combineReducers, configureStore } from "@reduxjs/toolkit";
// import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from 'redux-persist'

// import storage from 'redux-persist/lib/storage'; // Default storage is localStorage
// import { PersistGate } from 'redux-persist/integration/react';

// // Configuration for Redux Persist
// const persistConfig = {
//   key: 'root', // Key for the persisted state
//   storage,  
//   whitelist: Object.keys(reducers), // Add the reducers you want to persist
//   serializableCheck: {
//     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], // Add other actions to ignore as needed
//   },
// };

// // Combine your reducers into root reducer
// const rootReducer = combineReducers({
//   ...reducers,
//   // routing: routerReducer 
// });



// const history = createBrowserHistory();

// // Enhance root reducer with persistence capabilities
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// // Configure Redux store
// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) => 
//     getDefaultMiddleware() 
// });

// // Persist the store
// let persistor = persistStore(store);

// const AppNew = () => (
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}> {/* Adjust "null" with loading component if needed */}
//       <BrowserRouter history={history}>
//        <CreateRoutes store={store} />
//       </BrowserRouter>
//     </PersistGate>
//   </Provider>
// );

// export default AppNew;


import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { PersistGate } from "redux-persist/integration/react";
import CreateRoutes from "../routes/CreateRoutes";
import reducers from "../reducers/index";

// Configuration for Redux Persist
const persistConfig = {
  key: "root", // Key for the persisted state
  storage: storageSession,
  whitelist: Object.keys(reducers), // Add the reducers you want to persist
  serializableCheck: {
    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], // Add other actions to ignore as needed
  },
};

// Combine your reducers into root reducer
const rootReducer = combineReducers({
  ...reducers,
});

// Enhance root reducer with persistence capabilities
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Persist the store
let persistor = persistStore(store);

const AppNew = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {/* Adjust "null" with loading component if needed */}
      <BrowserRouter>
        <CreateRoutes store={store} />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default AppNew;
