import _ from "lodash";
import { connect } from "react-redux";

import Dashboard from "../components/dashboard";
import {
  archive,
  fetch,
  fetchCorporateSubaccount,
  fetchTimerAndViewsTotal,
} from "../actions/timerActions";
import { fetch as fetchSubaccountDetails, fetchSubaccountLatestTimer } from "../actions/subaccountActions";
import { updateStepCount } from "../actions/accountActions";

import { subscribe } from "../actions/authenticationActions";

const mapStateToProps = (state, props) => {
  // const activeTimers = _.get(state, "timers.data.active", []);
  const activeTimers = _.get(state, "timers.timerAndViewsTotal.active_timers_count", "");
  const archivedTimers = _.get(state, "timers.data.archived", []);
  // const loads =
  //   _.sumBy(activeTimers, "views") + _.sumBy(archivedTimers, "views");
  const loads = _.get(state, "timers.timerAndViewsTotal.total_views", "");
  const cycleViews = _.get(state, "timers.timerAndViewsTotal.cycle_views", "");
  const corporateSubaccounts = _.get(state, "timers.subaccounts", []);
  const freeOverrage =
    _.some(activeTimers, (t) => {
      return t.disableAt != null;
    }) ||
    _.some(archivedTimers, (t) => {
      return t.disableAt != null;
    });

  return {
    activeTimers: activeTimers ?? {},
    totalLoads: loads ?? {},
    cycleViews: cycleViews ?? 0,
    user: state.auth?.data ?? {},
    envContext: state?.envContext?.data ?? {},
    freeOverrage: freeOverrage ?? {},
    corporateSubaccounts: corporateSubaccounts ?? {},
    timers: state.timers?.data?.account_timers ?? {},
    subaccountDetails: state?.subaccountDetails ?? {},
    subaccountLatestTimer: state?.subaccountDetails?.subaccountLatestTimer ?? {},
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  archive: (timerId) => {
    dispatch(archive(timerId, true));
  },
  fetch: (subaccount_id) => {
    // dispatch(fetch());
    dispatch(fetchTimerAndViewsTotal(subaccount_id));
  },
  fetchCorporateSubaccount: () => {
    dispatch(fetchCorporateSubaccount());
  },
  subscribe: (stripeToken, plan) => {
    dispatch(subscribe(stripeToken, plan));
  },
  fetchSubaccountDetails: (subaccount_id) => {
    dispatch(fetchSubaccountDetails(subaccount_id));
  },
  updateStepCount: (step_count) => {
    dispatch(updateStepCount(step_count));
  },
  fetchSubaccountLatestTimer: (subaccount_id) => {
    dispatch(fetchSubaccountLatestTimer(subaccount_id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
