import React from "react";
import PropTypes from "prop-types";
import _, { filter } from "lodash";
import moment from "moment";
import {
  Button,
  Form,
  Grid,
  Icon,
  Modal,
  Pagination,
  Popup,
  Table,
} from "semantic-ui-react";
import { Tab } from "semantic-ui-react";
import css from "../../assets/styles/dashboard/index.module.scss";
// import commonCss from "../../assets/styles/shared/common.scss";
import tableCss from "../../assets/styles/shared/tables.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  DeleteIconComponent,
  EditIconComponent,
  LeftArrowIconComponent,
  PersonIconComponent,
  RightArrowIconComponent,
  SubaccountSuccessWithInviteUserIconComponent,
} from "../iconComponents";

import { createBrowserHistory } from "history";

import Clipboard from "react-clipboard.js";
import TimerPreview from "../shared/timerPreview";
import InviteUserComponent from "../inviteUser";
import NewEditableText from "../editor/newEditableText";
import { Input } from "semantic-ui-react";

import editatbleCss from "../../assets/styles/editor/editableText.module.scss";
import { ClipLoader } from "react-spinners";
const browserHistory = createBrowserHistory();

const { array, object, string } = PropTypes;

const initialState = {
  isModalOpen: false,
  email: "",
  name: "",
  subaccountUserActivePage: 1,
  totalSubaccountUserPages: 0,
  subaccountName: "",
  originalSubaccountName: "",
  recordLimit: 5,
  delete_user_id: 0,
  isDeleteModalOpen: false,
  subaccountUserStatus: [true, false, false, false, false],
  subaccountUserDetails: [
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
    { name: "", email: "", valid: true },
  ],
  step: 1,
  editing: false,
  firstTime: false,
  count: 0,
  prevcount: 0,
};

class SubaccountDetailPage extends React.Component {
  static propTypes = {
    subaccount: object,
    subaccount_users: array,
    subaccount_timers: array,
    envContext: object,
    user: object,
    auth: object,
    error: string,
  };

  constructor(props) {
    super(props);
    this.state = { ...initialState };
    this.updateValues = this.updateValues.bind(this);
  }

  updateValues(key, values) {
    this.setState({ [key]: values });
  }

  componentDidMount() {
    // console.log(this.props);
    if (this.props.params) {
      // if (this.props.params.id) {
      let id = parseInt(this.props.params);
      this.props.fetch(id);
      this.props.fetchSubaccountUsers(id);
      this.props.fetchSubaccountTimers(id);
      // }
    }
  }

  getResetState() {
    return {
      ...initialState,
    };
  }

  handleToggleSubaccountUserModal = (isOpen) => {
    return () => {
      this.setState({
        ...this.getResetState(),
        isModalOpen: isOpen,
      });
    };
  };

  processSubaccountAction = () => {
    let stepValue = this.state.step;
    if (stepValue == 1) {
      this.handleCreateSubaccountUser();
      this.setState({ step: 2 });
    }
  };

  renderActions = () => {
    return (
      // <>
      // <Button basic onClick={this.handleToggleSubaccountUserModal(false)}>
      //     Cancel
      // </Button>
      // <Button color="green" disabled={!this.validSubaccountUserFormData()}>
      //     Save
      // </Button>
      // </>
      <div className={tableCss.buttonContainer}>
        <Button basic onClick={this.handleToggleSubaccountUserModal(false)}>
          Cancel
        </Button>
        <Button
          className={tableCss.primaryButton}
          onClick={this.processSubaccountAction}
          disabled={!this.validSubaccountUserFormData()}
        >
          Send invites
        </Button>
      </div>
    );
  };

  validSubaccountUserFormData = () => {
    return this.totalActiveUser() > 0;
  };

  handleCreateSubaccountUser = () => {
    let userStatus = [...this.state.subaccountUserStatus];
    let users = [];
    for (let index = 0; index < userStatus.length; index++) {
      if (userStatus[index]) {
        let user = { name: "", email: "", password: "admin@1234" };
        user.name = this.state.subaccountUserDetails[index].name;
        user.email = this.state.subaccountUserDetails[index].email;
        users.push(user);
      }
    }
    this.props.createSubaccount(users, this.props.subaccount.id);
  };

  handleChangeEventCurry = (key) => {
    return (event, data) => {
      this.setState({ [key]: data.value });
    };
  };

  renderZeroSubaccountUserState = () => {
    return (
      <div className={tableCss.zero}>
        You do not currently have any subaccount users. Create one with the
        button in the bottom right!
      </div>
    );
  };

  formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };

  openDeleteDialog = (userId) => {
    this.setState({ delete_user_id: userId, isDeleteModalOpen: true });
  };

  renderSubaccountUserTemplates = () => {
    const orderedSubaccountUsers = _.orderBy(
      this.props.subaccount_users,
      ["updated_at", "name"],
      ["desc", "asc"]
    );
    const beginIndex =
      (this.state.subaccountUserActivePage - 1) * this.state.recordLimit;
    const endIndex = beginIndex + this.state.recordLimit;
    const sliceSubaccountUsers = orderedSubaccountUsers.slice(
      beginIndex,
      endIndex
    );
    return _.map(sliceSubaccountUsers, (user) => {
      return (
        <Table.Row key={"subaccount_user_" + user.id}>
          <Table.Cell className={tableCss.name} style={{ borderTop: "0px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingRight: "20px", paddingTop: "5px" }}>
                <PersonIconComponent />
              </span>
              <span>{user.name}</span>
            </div>
          </Table.Cell>
          <Table.Cell className={tableCss.name} style={{ borderTop: "0px" }}>
            {user.email}
          </Table.Cell>
          <Table.Cell className={tableCss.name} style={{ borderTop: "0px" }}>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => this.openDeleteDialog(user.id)}
            >
              <DeleteIconComponent />
            </span>
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  handleSubaccountUserPaginationChange = (e, { activePage }) =>
    this.setState({ subaccountUserActivePage: activePage });

  renderSubaccountUserTableContents = () => {
    if (_.isEmpty(this.props.subaccount_users)) {
      return this.renderZeroSubaccountUserState();
    } else {
      return (
        <Table basic="very">
          {/* <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>                    
                    <Table.HeaderCell>Email</Table.HeaderCell>                    
                    <Table.HeaderCell>Created at</Table.HeaderCell>    
                  </Table.Row>
                </Table.Header> */}
          <Table.Body>{this.renderSubaccountUserTemplates()}</Table.Body>
          <Table.Footer style={{ textAlign: "right" }}>
            <Table.Row>
              <Table.HeaderCell colSpan="3" style={{ borderTop: "0px" }}>
                <Pagination
                  defaultActivePage={this.state.subaccountUserActivePage}
                  onPageChange={this.handleSubaccountUserPaginationChange}
                  totalPages={this.state.totalSubaccountUserPages}
                  lastItem={null}
                  firstItem={null}
                  ellipsisItem={{
                    content: <Icon name="ellipsis horizontal" />,
                    icon: true,
                  }}
                  prevItem={{
                    content: (
                      <span>
                        <LeftArrowIconComponent />
                      </span>
                    ),
                    icon: true,
                  }}
                  nextItem={{
                    content: (
                      <span>
                        <RightArrowIconComponent />
                      </span>
                    ),
                    icon: true,
                  }}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      );
    }
  };

  renderSubaccountDashboard = () => {
    const filteredTimers = _.filter(
      this.props.subaccount_timers,
      function (timer) {
        return !timer.archived;
      }
    );
    let totalTimer = filteredTimers.length;
    let totalActiveView = 0;
    let totalPaidView = 0;
    for (let i = 0; i < totalTimer; i++) {
      totalActiveView += parseInt(filteredTimers[i].total_views);
      totalPaidView += parseInt(filteredTimers[i].paid_views);
    }
    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
        <div
          style={{
            flex: "50%",
            height: "300px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: "#F7F8F8",
          }}
        >
          <h1>{totalTimer}</h1>
          <p>Active Timers</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "3px",
            flex: "50%",
            height: "300px",
          }}
        >
          <div
            style={{
              flex: "50%",
              height: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              backgroundColor: "#F7F8F8",
            }}
          >
            <h1>{totalActiveView}</h1>
            <p>Active views</p>
          </div>
          <div
            style={{
              flex: "50%",
              height: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              backgroundColor: "#F7F8F8",
            }}
          >
            <h1>{totalPaidView}</h1>
            <p>Views (this billing cycle)</p>
          </div>
        </div>
      </div>
    );
  };

  processDeleteSubaccountUser = () => {
    this.props.deleteUser(this.state.delete_user_id);
    this.setState({
      ...this.getResetState(),
      isDeleteModalOpen: false,
      delete_user_id: 0,
    });
  };

  handleDeleteToggleModal = (isOpen) => {
    return () => {
      this.setState({
        ...this.getResetState(),
        isDeleteModalOpen: isOpen,
        delete_user_id: 0,
      });
    };
  };

  renderDeleteUserModal = () => {
    return (
      <Modal
        size={"tiny"}
        open={this.state.isDeleteModalOpen}
        onClose={this.handleDeleteToggleModal(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Delete Subaccount User</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete your subaccount user</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.handleDeleteToggleModal(false)}>
            No
          </Button>
          <Button positive onClick={this.processDeleteSubaccountUser}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  subaccountNameChangeHandler = (event, data) => {
    this.setState({ subaccountName: data.value });
  };

  static getDerivedStateFromProps(props, current_state) {
    let changed = false;
    let newStates = { ...current_state };
    if (props.subaccount_users && props.subaccount_users.length > 0) {
      let totalPage = Math.floor(props.subaccount_users.length / 5);
      if (props.subaccount_users.length % 5 != 0) {
        totalPage++;
      }
      newStates.totalSubaccountUserPages = totalPage;
      changed = true;
    }
    if (props.subaccount && props.subaccount.name) {
      if (!newStates.firstTime) {
        newStates.subaccountName = props.subaccount.name;
        newStates.firstTime = true;
        changed = true;
      } else {
        if (!current_state.editing) {
          newStates.firstTime = false;
          changed = true;
        }
      }
    }
    if (props.error != null && newStates.prevcount != newStates.count) {
      newStates.prevcount = newStates.count;
      newStates.subaccountName = props.subaccount.name;
      changed = true;
    }
    if (changed) {
      return newStates;
    }
    return null;
  }

  toggleEdit = (status) => {
    this.setState({ editing: status });
    if (!status) {
      if (this.props.subaccount.name != this.state.subaccountName) {
        let value = this.state.count + 1;
        this.setState({ count: value });
        this.props.updateSubaccountName(
          this.props.subaccount.id,
          this.state.subaccountName
        );
      }
    }
  };

  prepareEditableText = () => {
    return (
      <>
        {this.state.editing && (
          <Input
            fluid
            autoFocus
            className={editatbleCss.textHeader}
            onChange={this.subaccountNameChangeHandler}
            onBlur={() => this.toggleEdit(false)}
            value={this.state.subaccountName}
          />
        )}
        {!this.state.editing && (
          <div
            className={editatbleCss.textHeader}
            onClick={() => this.toggleEdit(true)}
          >
            {this.state.subaccountName} <Icon name="pencil" size="small" />
          </div>
        )}
      </>
    );
  };

  render() {
    // console.log(this.props.subaccount, "hey");

    if (this.props.isSubAccountLoading) {
      return (
        <div className={editatbleCss.full_loader}>
          <ClipLoader />
        </div>
      );
    } else if (!_.isEmpty(this.props.subaccount)) {
      return (
        <>
          <Link to="/accounts" className={css.seeAllButton}>
            <i className="arrow left icon"></i> Accounts
          </Link>
          <br />
          <br />
          {/* <h1 className={commonCss.heading3}>{this.props.subaccount.name}</h1> */}
          {this.prepareEditableText()}
          {/* <NewEditableText name={this.state.subaccountName} onChange={this.subaccountNameChangeHandler("subaccountName")}/> */}
          <br />
          <Grid>
            <Grid.Row style={{ paddingBottom: "0px" }}>
              <Grid.Column width={8}>
                <p className={tableCss.heading2}>Account overview</p>
              </Grid.Column>
              <Grid.Column width={8}>
                <div className={tableCss.filterDiv}>
                  <div>
                    <p className={tableCss.heading2}>
                      Users of this sub account
                    </p>
                  </div>
                  <div>
                    <Button
                      onClick={this.handleToggleSubaccountUserModal(true)}
                      className={tableCss.seeAllButton}
                    >
                      invite user
                    </Button>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: "0px" }}>
              <Grid.Column width={8}>
                {this.renderSubaccountDashboard()}
              </Grid.Column>
              <Grid.Column width={8}>
                {this.renderSubaccountUserTableContents()}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {this.renderModal()}
          <div
            id="timer-list"
            className={tableCss.tableContainer}
            style={{ marginTop: "15px" }}
          >
            {this.renderTabs()}
          </div>
          {this.renderDeleteUserModal()}
        </>
      );
    } else {
      return <h1>There is no such subaccount.</h1>;
    }
  }

  // Tabs coding begins here
  renderTabs = () => {
    const tables = [
      { menuItem: "Active Timers", render: this.renderTable("active") },
      { menuItem: "Archived Timers", render: this.renderTable("archived") },
    ];

    return <Tab menu={{ secondary: true, pointing: true }} panes={tables} />;
  };

  renderTable = (status) => {
    return () => {
      return (
        <Tab.Pane as="div" attached="false">
          {this.renderTableContents(status)}
        </Tab.Pane>
      );
    };
  };

  renderZeroState = (status) => {
    if (status === "active") {
      return (
        <div className={tableCss.zero}>
          You do not currently have any active timers. Create one with the
          button in the top right!
        </div>
      );
    } else {
      return (
        <div className={tableCss.zero}>
          You do not currently have any archived timers.
        </div>
      );
    }
  };

  formatDate = (date) => {
    // return date.format("MMMM Do YYYY, h:mma z");
    return date.format("MMM DD, YYYY HH:mm");
  };

  renderTableContents = (status) => {
    const filteredTimers = _.filter(
      this.props.subaccount_timers,
      function (timer) {
        if (status == "active") {
          return !timer.archived;
        }
        return timer.archived;
      }
    );

    if (_.isEmpty(filteredTimers)) {
      return this.renderZeroState(status);
    } else {
      return (
        <Table basic>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Creation Date</Table.HeaderCell>
              <Table.HeaderCell>End Date</Table.HeaderCell>
              <Table.HeaderCell>Total views</Table.HeaderCell>
              <Table.HeaderCell>
                Views{" "}
                <span className={tableCss.secondary}>(this billing cycle)</span>
              </Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row className={tableCss.tableRowBackground}>
              <Table.HeaderCell className={tableCss.tableCol} colSpan={"6"}>
                {filteredTimers.length} total timers
              </Table.HeaderCell>
            </Table.Row>
            {this.renderTimers(filteredTimers)}
          </Table.Body>
        </Table>
      );
    }
  };

  renderTimers = (timers, showUid) => {
    const orderedTimers = _.orderBy(
      timers,
      ["ends_at", "name"],
      ["desc", "asc"]
    );
    return _.map(orderedTimers, this.renderTimerCurry());
  };

  renderTimerCurry = () => {
    return (timer) => {
      let endsAt = moment(timer.ends_at).tz(timer.time_zone);
      endsAt = this.formatDate(endsAt);
      let createdAt = moment(timer.created_at).tz(timer.time_zone);
      createdAt = this.formatDate(createdAt);
      const timerUrl = timer.url;
      return (
        <Table.Row key={"subaccount_timer_" + timer.timer_id}>
          <Table.Cell className={tableCss.name}>
            <Popup
              basic
              position="right center"
              trigger={<Icon name="eye" />}
              content={
                <TimerPreview
                  type="Timer"
                  timer={timer}
                  genUrl={this.props?.user?.gen_url}
                />
              }
            />
          </Table.Cell>
          <Table.Cell>{createdAt}</Table.Cell>
          <Table.Cell>{endsAt}</Table.Cell>
          <Table.Cell>{timer.total_views}</Table.Cell>
          <Table.Cell>{timer.paid_views}</Table.Cell>
          <Table.Cell className={tableCss.actionDiv}>
            {this.renderEditIcon(timer.archived, timer)}
            {this.renderArchiveIcon(timer.archived, timer.timer_id)}
            {this.renderCopyLink(timerUrl)}
          </Table.Cell>
        </Table.Row>
      );
    };
  };

  renderEditIcon = (archived, timer) => {
    const { user } = this.props;

    if (archived) {
      return null;
    }

    return (
      <Popup
        inverted
        // trigger={<Icon name="edit" onClick={this.handleEdit(timer.id)} />}
        trigger={
          <span
            style={{ cursor: "pointer" }}
            onClick={this.handleEdit(timer.timer_id)}
          >
            <EditIconComponent />
          </span>
        }
        content={"Edit"}
        position={"top center"}
      />
    );
  };

  handleEdit = (timerId) => {
    return () => {
      this.props.navigate("/timer/" + timerId);
    };
  };

  renderArchiveIcon = (archived, id) => {
    const info = archived ? "Unarchive" : "Archive";

    return (
      <Popup
        inverted
        // trigger={<Icon name="archive" onClick={this.handleArchive(id, archived)} />}
        trigger={
          <span
            style={{ cursor: "pointer" }}
            name="trash"
            onClick={this.handleArchive(id, archived)}
          >
            <DeleteIconComponent />
          </span>
        }
        content={info}
        position={"top center"}
      />
    );
  };

  handleArchive = (timerId, archived) => {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.props.archive(timerId, !archived);
    };
  };

  renderCopyLink(url) {
    if (url) {
      const copyButton = (
        <Clipboard component="span" data-clipboard-text={url}>
          <Icon name="linkify" className={tableCss.linkIcon} />
        </Clipboard>
      );

      return (
        <Popup
          inverted
          trigger={copyButton}
          content="Copied!"
          position="top center"
          hideOnScroll
        />
      );
    } else {
      return (
        <Popup
          inverted
          content="Upgrade your plan to use this timer"
          position="top center"
          hideOnScroll
          trigger={<Icon name="linkify" disabled />}
        />
      );
    }
  }

  emailIsValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  // Subacction Multi User Creation
  totalActiveUser = () => {
    let count = 0;
    let userStatus = [...this.state.subaccountUserStatus];
    let users = [...this.state.subaccountUserDetails];
    for (let index = 0; index < userStatus.length; index++) {
      let email = users[index].email;
      if (
        userStatus[index] &&
        !_.isEmpty(email) &&
        this.emailIsValid(email) &&
        users[index].valid
      ) {
        count++;
      }
    }
    return count;
  };

  renderModal = () => {
    return (
      <Modal
        size={"small"}
        open={this.state.isModalOpen}
        onClose={this.handleToggleSubaccountUserModal(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Content
          style={{ paddingTop: this.state.step == 2 ? "0px" : "32px" }}
          scrolling={this.state.step == 1}
        >
          {this.state.step == 1 && (
            <h1 className={tableCss.headerFont}>Invite users</h1>
          )}
          {this.state.step == 1 && (
            <InviteUserComponent
              id="subaccount_user_form"
              statusKey="subaccountUserStatus"
              valueKey="subaccountUserDetails"
              updateValues={this.updateValues}
              basicPassword={this.props.envContext.basicPassword}
              token={this.props.auth?.data?.jwt}
            />
          )}
          {this.state.step == 2 && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: this.state.step == 3 ? "0px" : "10px",
                  justifyContent: "flex-start",
                  minHeight: "200px",
                  alignItems: "center",
                }}
              >
                <span>
                  <SubaccountSuccessWithInviteUserIconComponent />
                </span>
                <h1 className={tableCss.headerFont}>Invites sent!</h1>
                <p className={tableCss.textSmall}>
                  Invites sent to {this.totalActiveUser()} users.
                </p>
                <div>
                  <Button
                    onClick={this.handleToggleSubaccountUserModal(false)}
                    className={tableCss.primaryButton}
                  >
                    Ok
                  </Button>
                </div>
              </div>
            </>
          )}
        </Modal.Content>
        {this.state.step == 1 && (
          <Modal.Actions style={{ background: "none" }}>
            {this.renderActions()}
          </Modal.Actions>
        )}
      </Modal>
    );
  };
}

const SubaccountDetails = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  return <SubaccountDetailPage {...props} params={id} navigate={navigate} />;
};

export default SubaccountDetails;
