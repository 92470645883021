import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Logout from "../components/logout";
import { logout } from "../actions/authenticationActions";
import getRedirectLocation from "../helpers/getRedirectLocation";

const LogoutContainer = () => {
  // console.log("I m here")

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    // console.log("i am here")
    // const redirect = getRedirectLocation(window.location);
    const redirect = "/";
    dispatch(logout(navigate, { redirect }));
  };

  return <Logout logout={handleLogout} />;
};

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   logout: () => {
//     const redirect = getRedirectLocation(ownProps.location);
//     dispatch(logout({ redirect }));
//   }
// });

export default LogoutContainer;
