// import PropTypes from 'prop-types';
// import React from "react";
// // import ReactS3Uploader from "react-s3-uploader";
// import { Popup, Button, Input, Icon, Label, Form, Progress } from "semantic-ui-react";

// // import css from "styles/shared/uploader.scss";

// import css from '../../assets/styles/shared/uploader.module.scss'

// import PlanBadge from '../editor/planBadge';

// const { string, func, array,bool } = PropTypes;

// class FileUploader extends React.Component {
//   static propTypes = {
//     label: string,
//     existingFile: string,
//     handleChange: func,
//     handleError: func,
//     disableStatus:bool,
//     fallbackBadgeStatus:bool,
//   };

//   static defaultProps = {
//     disableStatus: false,
//     fallbackBadgeStatus: false
//   };

//   constructor(props) {
//     super(props)

//     this.state = { progress: null };
//   }

//   renderButton() {
//     if (this.state.progress !== null) {
//       return (
//         <Progress percent={this.state.progress} color='blue' />
//       );
//     } else if (this.props.existingFile) {
//       return (
//         <Button.Group fluid>
//           <Button
//             content={"Replace file..."}
//             labelPosition="left"
//             icon="file"
//             onClick={() => this.uploadInput.click()}
//           />
//           <Button negative compact
//             onClick={() => this.props.handleChange(null)}>
//             <Icon name='trash' />
//           </Button>
//         </Button.Group>
//       )
//     } else {
//       return (
//         <Button fluid
//           content={"Choose file..."}
//           labelPosition="left"
//           icon="file"
//           onClick={() => this.uploadInput.click()
//           }

//         />
//       )
//     }
//   };

//   // renderUploader() {
//   //   return (
//   //     <ReactS3Uploader
//   //       signingUrl="/api/v1/uploads/sign"
//   //       signingUrlMethod="GET"
//   //       accept="image/*"
//   //       hidden
//   //       signingUrlQueryParams={this.getQueryParams}
//   //       preprocess={this.handlePreprocess}
//   //       onError={this.handleUploadError}
//   //       onFinish={this.handleUploadComplete}
//   //       onProgress={this.handleProgress}
//   //       inputRef={cmp => this.uploadInput = cmp} />
//   //   )
//   // };

//   renderLabel = () => {
//     return (
//       <>
//         <span className={css.uploadLabel}>{this.props.label}</span>
//         <PlanBadge
//             show={!this.props.fallbackBadgeStatus && this.props.disableStatus}
//             label="POWER"
//             popup="Fallback image is available in Power plan."
//             inUse={this.props.existingFile}
//           />
//       </>
//     );
//   };

//   render() {
//     return (<div className="field">
//       {this.renderLabel()}
//       {this.renderButton()}
//       {/* {this.renderUploader()} */}
//     </div>
//     );
//   }

//   getQueryParams = () => {
//     return { size: this.fileSize }
//   }

//   handlePreprocess = (file, next) => {
//     this.fileSize = file.size;
//     if (this.fileSize > 1024 * 1024) {
//       this.handleUploadError("File too large (Limit 1MB)")
//     } else {
//       next(file);
//     }
//   }

//   handleProgress = (percent) => {
//     this.setState({ progress: percent })
//   }

//   handleUploadError = (err) => {
//     this.setState({ progress: null })
//     this.props.handleError(err);
//   };

//   handleUploadComplete = (response) => {
//     this.setState({ progress: null })
//     this.props.handleChange(response.filePath);
//   };
// }

// export default FileUploader;

import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { uploadFile } from "../../actions/authenticationActions"; // Adjust the import path as necessary
import {
  Popup,
  Button,
  Input,
  Icon,
  Label,
  Form,
  Progress,
} from "semantic-ui-react";
import css from "../../assets/styles/shared/uploader.module.scss";
import PlanBadge from "../editor/planBadge";

const { string, func, bool } = PropTypes;

class FileUploader extends React.Component {
  static propTypes = {
    label: string,
    existingFile: string,
    handleChange: func,
    handleError: func,
    disableStatus: bool,
    fallbackBadgeStatus: bool,
    uploadFile: func.isRequired, // Add uploadFile as a required prop
    progress: PropTypes.number,
  };

  static defaultProps = {
    disableStatus: false,
    fallbackBadgeStatus: false,
  };

  constructor(props) {
    super(props);
    this.state = { progress: null };
    this.uploadInput = React.createRef();
  }

  handleFileUpload = (event) => {
    const file = event?.target?.files[0];
    this.setState({ progress: "100" }); // handling uploading progressbar state
    this.props
      .uploadFile(file)
      .then((filePath) => {
        if (this.props.handleChange) {
          this.props.handleChange(filePath);
        }
        this.setState({ progress: null });
      })
      .catch((error) => {
        if (this.props.handleError) {
          this.props.handleError(error);
        }
        this.setState({ progress: null });
      });
  };

  // handleUploadComplete = (file) => {
  //   this.setState({ progress: null });
  //   if (this.props.handleChange) {
  //     this.props.handleChange(file);
  //   }
  // };

  // handleUploadError = (error) => {
  //   this.setState({ progress: null });
  //   if (this.props.handleError) {
  //     this.props.handleError(error);
  //   }
  // };

  // getQueryParams = (fileMetadata) => {
  //   return {
  //     objectName: fileMetadata.objectName,
  //     contentType: fileMetadata.contentType,
  //     size: fileMetadata.size
  //   };
  // };

  renderButton() {
    // console.log(this.state.progress);
    if (this.state.progress !== null) {
      return (
        <Progress
          percent={this.state.progress}
          color="blue"
          className="progress-bar-success progress-bar-striped"
        />
      );
    } else if (this.props.existingFile) {
      return (
        <Button.Group fluid>
          <Button
            content={"Replace file..."}
            labelPosition="left"
            icon="file"
            onClick={() => this.uploadInput.current.click()}
          />
          <Button
            negative
            compact
            onClick={() => this.props.handleChange(null)}
          >
            <Icon name="trash" />
          </Button>
        </Button.Group>
      );
    } else {
      return (
        <Button
          fluid
          content={"Choose file..."}
          labelPosition="left"
          icon="file"
          onClick={() => this.uploadInput.current.click()}
        />
      );
    }
  }

  renderLabel = () => {
    return (
      <>
        <span className={css.uploadLabel}>{this.props.label}</span>
        <PlanBadge
          show={!this.props.fallbackBadgeStatus && this.props.disableStatus}
          label="POWER"
          popup="Fallback image is available in Power plan."
          inUse={this.props.existingFile}
        />
      </>
    );
  };

  render() {
    return (
      <div className="field">
        {this.renderLabel()}
        {this.renderButton()}
        <input
          type="file"
          accept="image/*"
          hidden
          ref={this.uploadInput}
          onChange={this.handleFileUpload}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  progress: state.upload?.progress || null, // Use optional chaining to avoid errors if upload is undefined
});

const mapDispatchToProps = {
  uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);
