// import axios from "axios";
// import ReactOnRails from "react-on-rails";

// export default function generateApiClient(getState) {
//   const state = getState();

//   const headers = ReactOnRails.authenticityHeaders({
//     "Accept": "application/json",
//     "Content-Type": "application/json",
//     "X-BasicPassword": state.envContext.data.basicPassword,
//   });

//   if (state.auth.data && state.auth.data.token) {
//     headers["Authorization"] = `Bearer ${state.auth.data.token}`;
//   } else {
//     headers["Authorization"] = "";
//   }

//   return axios.create({
//     timeout: 20000,
//     headers: headers,
//     responseType: "json"
//   });
// }

// http://localhost:5000/account_activations/5l-Odontk63HoKITBJY4oQ/edit?email=daljeet%2Bsingh%2B1001%40trigvent.com

// import axios from "axios";

// const apiUrl = process.env.REACT_APP_DOMAIN_URL;

// export default function generateApiClient(getState) {
//   const state = getState();

//   // console.log("apiUrl",apiUrl);

//   // Headers
//   const headers = {
//     "Accept": "application/json",
//     "Content-Type": "application/json",
//   };

//   if (state?.auth?.data && state?.auth?.data?.jwt) {
//     // console.log("state.auth.data.jwt=",state.auth.data.jwt);
//     headers["Authorization"] = `Bearer ${state.auth.data.jwt}`;
//   } else {
//     headers["Authorization"] = "";
//   }

//   // Create Axios instance
//   return axios.create({
//     baseURL: apiUrl, // Base URL for your requests
//     timeout: 20000,
//     headers: headers,
//     responseType: "json"
//   });
// }

import axios from "axios";
import { AUTH_TOKEN } from "../constants/authenticationConstants";
import Cookies from "js-cookie";

const apiUrl = process.env.REACT_APP_DOMAIN_URL;
const pendingRequests = new Map();

export default function generateApiClient(getState) {
  const apiClient = axios.create({
    baseURL: apiUrl,
    timeout: 100000,
    responseType: "json",
  });

  apiClient.interceptors.request.use(
    (config) => {
      const state = getState();
      const jwtToken = state.auth.data && state.auth.data.jwt;

      if (config.method === "get" && !jwtToken) {
        return new Promise(() => {});
      }

      const requestKey = `${config.method}-${config.url}-${JSON.stringify(
        config.params || {}
      )}`;

      if (config.method === "get") {
        if (pendingRequests.has(requestKey)) {
          pendingRequests.get(requestKey).cancel("Duplicate request canceled.");
        }
      }
      const cancelTokenSource = axios.CancelToken.source();
      config.cancelToken = cancelTokenSource.token;

      pendingRequests.set(requestKey, cancelTokenSource);

      if (jwtToken) {
        config.headers["Authorization"] = `Bearer ${jwtToken}`;
      }

      return config;
    },
    (error) => {
      console.error("Request Error:", error);
      return Promise.reject(error);
    }
  );

  apiClient.interceptors.response.use(
    (response) => {
      const requestKey = `${response.config.method}-${
        response.config.url
      }-${JSON.stringify(response.config.params || {})}`;
      pendingRequests.delete(requestKey);
      return response;
    },
    (error) => {
      if (axios.isCancel(error)) {
        console.warn("Request canceled:", error.message);
        return new Promise(() => {});
      }

      if (error.response && error.response.status === 307) {
        const { redirect_url } = error?.response?.data;
        window.location.href = redirect_url;
        return true;
      } else {
        console.error("Error Message:", error.message);
      }

      const requestKey = `${error.config?.method}-${
        error.config?.url
      }-${JSON.stringify(error.config?.params || {})}`;
      pendingRequests.delete(requestKey);

      return Promise.reject(error);
    }
  );

  return apiClient;
}
