import React, { useEffect, useState } from "react";
import fontCss from "../../assets/styles/shared/customFont.module.scss";
import { Button, Input, Modal } from "semantic-ui-react";
import {
  CrossIconComponent,
  LightningBoltIconComponent,
} from "../iconComponents";
import { useSelector } from "react-redux";

import { createBrowserHistory } from "history";
//import css from "styles/editor/labelPicker.scss";

import css from "../../assets/styles/editor/labelPicker.module.scss";
import { useNavigate } from "react-router-dom";

const browserHistory = createBrowserHistory();
const FontUploader = (props) => {
  const userAllFonts = useSelector(
    (state) => state.subaccountDetails.custom_fonts
  );
  const userDetails = useSelector((state) => state.auth.data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // // console.log(userAllFonts);
  }, [userAllFonts]);

  const isCorporateUser = () => {
    return (
      (userDetails && userDetails?.is_company) ||
      (userDetails &&
        userDetails.features.subaccount &&
        userDetails.parent_id == 0)
    );
  };

  const isSubaccountUser = () => {
    return userDetails && userDetails.subaccount_id != 0;
  };

  const isNormalUser = () => {
    return !(isCorporateUser() || isSubaccountUser());
  };

  const jumpToSettings = () => {
    navigate("/settings");
  };

  const renderFontListModal = () => {
    return (
      <Modal
        size="tiny"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Modal.Content>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Font lists
            </p>
            <span
              onClick={() => setIsModalOpen(false)}
              style={{ cursor: "pointer" }}
            >
              <CrossIconComponent />
            </span>
          </div>
          {/* Add custom styles to adjust slider size using CSS class */}
          <div className={css.customSlider}>
            {/* Slider component with custom styles */}
            <div className={css.sliderContainer}>
              {userAllFonts.map((font) => (
                <div key={"font_list_" + font.id}>{font.font_name}</div>
              ))}
            </div>
          </div>
        </Modal.Content>
      </Modal>
    );
  };

  const jumpToSaleEmail = () => {
    window.open("mailto:sales@sendtric.com", "_blank");
  };

  return (
    <>
      <div className={fontCss.title}>Your fonts</div>
      {isNormalUser() && (
        <>
          <div className={fontCss.description} style={{ marginTop: "5px" }}>
            You have not uploaded any font yet
          </div>
          <div style={{ marginTop: "10px" }}>
            <Button disabled className={fontCss.addButton}>
              Add your font
            </Button>
          </div>

          <div
            className={fontCss.infoDiv}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            <span style={{ paddingTop: "4px" }}>
              <LightningBoltIconComponent />
            </span>
            <div className={fontCss.upgradeDiv}>
              <span className={fontCss.info}>
                Uploading custom fonts is available in the Enterprise plan.
              </span>
              <div>
                <span className={fontCss.upgradeLink} onClick={jumpToSaleEmail}>
                  Upgrade now
                </span>
              </div>
            </div>
          </div>
        </>
      )}
      {isCorporateUser() && (
        <>
          <div className={fontCss.description} style={{ marginTop: "5px" }}>
            You have uploaded {userAllFonts.length}{" "}
            {userAllFonts.length > 1 ? "fonts" : "font"}.<br />
            <span
              style={{
                paddingLeft: "3px",
                color: "#4E84A1",
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              onClick={() => setIsModalOpen(true)}
            >
              Full list of uploaded fonts
            </span>
          </div>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <Button
              primary
              className={fontCss.addButton}
              onClick={jumpToSettings}
            >
              Add your font
            </Button>
          </div>
          {renderFontListModal()}
        </>
      )}
    </>
  );
};

export default FontUploader;
