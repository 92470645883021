import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://8ced3d126967e7954f2ed0c2a2248523@o4508915215761408.ingest.us.sentry.io/4508918865264640",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      tracePropagationTargets: ["localhost", /^https:\/\/(.*\.)?sendtric\.com/],
    }),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.5,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
