import AppNew from '../src/stores/routerStore';
import React, { Component } from 'react';

// import '../src/assets/stylesheets/style.module.scss';
import './App.scss';

class App extends Component {

  render() {
    return (
      <AppNew />
    );
  }
}

export default App;
