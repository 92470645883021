// import { connect } from "react-redux";

// import ResetPassword from "../components/resetPassword";
// import { resetPassword } from "../actions/authenticationActions";

// const mapStateToProps = (state) => ({
//   error: state.auth.error
// });

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   resetPassword: (password, passwordConfirmation) => {
//     const email = ownProps.location.search.split("email=")[1];
//     const resetId = ownProps.params.id;

//     dispatch(resetPassword(email, resetId, password, passwordConfirmation));
//   }
// });

// export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

import { connect } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import {
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ResetPassword from "../components/resetPassword";
import { resetPassword } from "../actions/authenticationActions";
import { useState } from "react";

const ResetPasswordContainer = () => {
  const error = useSelector((state) => state.auth.error);
  const inProgress = useSelector((state) => state.auth.inProgress);
  const dispatch = useDispatch();
  const location = useLocation();

  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  // const resetId='MT0ZYhjp8Yf4tCnJ0cNr_A';
  const { id: resetId } = useParams(); // Fetch resetId from the URL parameters

  const handleResetPassword = (password, passwordConfirmation) => {
    const redirect = "/dashboard";
    // console.log("password", password);
    // console.log("passwordConfirmation", passwordConfirmation);
    // console.log("email", email);
    // // console.log("Email: ",email);
    // console.log("reset ID", resetId);

    dispatch(
      resetPassword(
        email,
        resetId,
        password,
        passwordConfirmation,
        navigate,
        setIsPending,
        {
          redirect,
        }
      )
    );
  };
  return (
    <ResetPassword
      error={error}
      resetPassword={handleResetPassword}
      isPending={isPending}
    />
  );
};

export default ResetPasswordContainer;
