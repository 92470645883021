// import { connect } from "react-redux";

// import CreateTimerButton from "../components/timerList/createTimerButton";
// import { create } from "../actions/timerActions";
// import { fetch as fetchTemplates } from "../actions/templateActions";

// const mapStateToProps = (state, props) => ({
//   templates: state.template?.data || [],
//   envContext: state.envContext?.data || {},
//   user: state.auth?.data || {},
//   disabled: props.disabled,
//   children: props.children,
//   size: props.size,
// });

// const mapDispatchToProps = (dispatch, ownProps) => ({
//   fetch: () => {
//     dispatch(fetchTemplates());
//   },
//   handleCreateTimer: (options) => {
//     dispatch(create(options));
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(CreateTimerButton);

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateTimerButton from "../components/timerList/createTimerButton";
import { create, fetch as fetchTemplates } from "../actions/timerActions";

const CreateTimerButtonContainer = (props) => {
  const templates = useSelector((state) => state.template?.data || []);
  const envContext = useSelector((state) => state.envContext?.data || {});
  const user = useSelector((state) => state.auth?.data || {});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [dispatch]);

  const handleCreateTimer = (options) => {
    const userObj = {
      email: user?.email,
      subscription: user?.tier,
      name: user?.subscription?.nickname,
      price: (user?.subscription?.price)/100,
    }
    dispatch(create(options, navigate, setIsSaving,userObj));
    
  };

  const fetchTemplatesAction = () => {
    dispatch(fetchTemplates());
  };

  return (
    <CreateTimerButton
      templates={templates}
      envContext={envContext}
      user={user}
      templateId={props?.templateId}
      disabled={props.disabled}
      children={props.children}
      size={props.size}
      handleCreateTimer={handleCreateTimer}
      fetch={fetchTemplatesAction}
      isSaving={isSaving}
    />
  );
};

export default CreateTimerButtonContainer;
