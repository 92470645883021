import { useEffect, useRef, useState } from "react";
import React from "react";
import { Button, Checkbox, Modal } from "semantic-ui-react";
import brandFont from "../../assets/styles/shared/corporateBrandFontEditor.module.scss";
import {
  BlueThreeDotIconComponent,
  ClipIconComponent,
  CrossIconComponent,
  GrayThreeDotIconComponent,
  GrayUploadIconComponent,
  WhiteUploadIconComponent,
} from "../iconComponents";
import BrandFontUploader from "./brandFontUploader";
import { useSelector } from "react-redux";
import NewFontSelector from "../shared/newFontSelector";

export default function CorporateBrandFontEditor(props) {
  const TOTAL_FONT_CONSTANT = 20;

  const [totalFonts, setTotalFonts] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [fileName, setFileName] = useState(null);
  const userAllFonts = useSelector(
    (state) => state.subaccountDetails.custom_fonts
  );
  const userDetails = useSelector((state) => state.auth.data);
  const [defaultFontStatus, setDefaultFontStatus] = useState(
    userDetails.use_default_status
  );
  const [digitFont, setDigitFont] = useState(userDetails.default_digit_font);
  const [labelFont, setLabelFont] = useState(userDetails.default_label_font);

  const [hoverStates, setHoverStates] = useState({});
  const [currentClickedFontID, setCurrentClickedFontID] = useState(0);
  const [currentEditFontID, setCurrentEditFontID] = useState(0);
  const [currentEditFontText, setCurrentEditFontText] = useState("");

  useEffect(() => {
    props.fetchFonts();
  }, []);

  useEffect(() => {
    setTotalFonts(userAllFonts.length);
    let states = {};
    for (let index = 0; index < userAllFonts.length; index++) {
      let font = userAllFonts[index];
      states["font_" + font.id] = false;
    }
    setHoverStates(states);
    setCurrentClickedFontID(0);
    setCurrentEditFontID(0);
    setCurrentEditFontText("");
  }, [userAllFonts]);

  const saveCustomFont = (filePath) => {
    if (props.createCustomFont) {
      // console.log(" File PATH: ", filePath)
      let file_name = fetchOnlyFileName(filePath);
      props.createCustomFont(filePath, file_name);
      setIsModalOpen(false);
    }
  };

  const fetchOnlyFileName = (filePath) => {
    let parts = filePath.split("-");
    if (parts.length > 5) {
      let newFileName = "";
      for (let index = 5; index < parts.length; index++) {
        newFileName += parts[index];
      }
      return newFileName.split(".ttf")[0];
    }

    return filePath;
  };

  const fetchFileName = (filePath) => {
    let parts = filePath.split("-");
    if (parts.length > 5) {
      let newFileName = "";
      for (let index = 5; index < parts.length; index++) {
        newFileName += parts[index];
      }
      return newFileName;
    }

    return filePath;
  };

  const renderModal = () => {
    return (
      <Modal
        size={"small"}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <BrandFontUploader
            existingFile={fileName}
            handleChange={setFileName}
            handleUploadStatus={setUploadStatus}
            onModalClose={() => setIsModalOpen(false)}
            saveFont={saveCustomFont}
            handleError={props.handleUploadError}
          />
        </Modal.Content>
      </Modal>
    );
  };

  const changeHoverState = (id, status) => {
    let states = { ...hoverStates };
    states["font_" + id] = status;
    setHoverStates(states);
  };

  const clickHandler = (event, id) => {
    event.stopPropagation();
    if (id != currentClickedFontID) {
      setCurrentClickedFontID(id);
    } else {
      setCurrentClickedFontID(0);
    }
  };

  const editClickHandler = (id, event, font_name) => {
    event.stopPropagation();
    setCurrentClickedFontID(0);
    setCurrentEditFontID(id);
    setCurrentEditFontText(font_name);
    changeHoverState(id, false);
  };

  const deleteClickHandler = (id, event) => {
    event.stopPropagation();
    if (props.removeCustomFont) {
      props.removeCustomFont(id);
      setCurrentClickedFontID(0);
      setCurrentEditFontID(0);
      setCurrentEditFontText("");
    }
  };

  const onChangeHandler = (event) => {
    setCurrentEditFontText(event.target.value);
  };

  const onBlurHandler = () => {
    if (props.updateCustomFont) {
      props.updateCustomFont(currentEditFontID, currentEditFontText);
      setCurrentEditFontID(0);
      setCurrentEditFontText("");
    }
  };

  const changeDefaultStatus = () => {
    let status = !defaultFontStatus;
    setDefaultFontStatus(status);
    // Call of Update
    props.updateUserDefaultFont(status, digitFont, labelFont);
  };

  const handleUpdateDefaultFont = (font_value) => {
    setDigitFont(font_value);
    props.updateUserDefaultFont(defaultFontStatus, font_value, labelFont);
  };
  const handleUpdateLabelFont = (font_value) => {
    setLabelFont(font_value);
    props.updateUserDefaultFont(defaultFontStatus, digitFont, font_value);
  };

  const closeMenuOption = () => {
    let newStates = { ...hoverStates };
    for (let key in newStates) {
      newStates[key] = false;
    }
    setHoverStates(newStates);
    setCurrentClickedFontID(0);
    setCurrentEditFontID(0);
    setCurrentEditFontText("");
  };

  return (
    <>
      <div className={brandFont.firstRow} onClick={closeMenuOption}>
        <div className={brandFont.firstRowColumn}>
          <div className={brandFont.uploadFontStyle}>Uploaded Fonts</div>
          <div className={brandFont.statStyle}>
            {totalFonts}/{TOTAL_FONT_CONSTANT}
          </div>
        </div>
        {totalFonts > 0 && totalFonts < 20 && (
          <div>
            <Button
              className={brandFont.firstRowUploadBtn}
              onClick={() => setIsModalOpen(true)}
            >
              <span>
                <GrayUploadIconComponent />
              </span>
              Upload font
            </Button>
          </div>
        )}
      </div>
      <div style={{ height: "30px" }} onClick={closeMenuOption}></div>
      {totalFonts == 0 && (
        <div className={brandFont.secondRow}>
          <div className={brandFont.secondRowTitle}>Add your custom fonts</div>
          <div className={brandFont.secondRowDescription}>
            Upload custom fonts so they’re available in all subaccounts.
          </div>
          <div>
            <Button
              className={brandFont.secondRowUploadBtn}
              onClick={() => setIsModalOpen(true)}
            >
              <span>
                <WhiteUploadIconComponent />
              </span>{" "}
              Upload font
            </Button>
          </div>
        </div>
      )}
      {totalFonts != 0 && (
        <div className={brandFont.thirdRow} onClick={closeMenuOption}>
          {userAllFonts.map((font, index) => {
            return (
              <div
                className={brandFont.thirdRowColumn}
                key={"font_details_" + font.id}
              >
                {/* <div className={brandFont.thirdRowColumnHeading}>
                  <span>The quick brown fox..</span>
                </div> */}

                <div className={brandFont.thirdRowColumnDataRow}>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <ClipIconComponent />
                    </div>
                    <div className={brandFont.thirdRowColumnDataRowColumn1}>
                      <div className={brandFont.thirdRowColumnFontName}>
                        {currentEditFontID != font.id && (
                          <span>{font.font_name}</span>
                        )}
                        {currentEditFontID == font.id && (
                          <input
                            className={brandFont.inputText}
                            type="text"
                            value={currentEditFontText}
                            onChange={onChangeHandler}
                            onBlur={onBlurHandler}
                          />
                        )}
                      </div>
                      <div className={brandFont.thirdRowColumnFontPath}>
                        {fetchFileName(font.font_path)}
                      </div>
                    </div>
                  </div>
                  <div
                    className={brandFont.thirdRowThreeDot}
                    onClick={(event) => clickHandler(event, font.id)}
                    onMouseOver={() => changeHoverState(font.id, true)}
                    onMouseOut={() => changeHoverState(font.id, false)}
                  >
                    {hoverStates["font_" + font.id] && (
                      <BlueThreeDotIconComponent />
                    )}
                    {!hoverStates["font_" + font.id] && (
                      <GrayThreeDotIconComponent />
                    )}
                    {font.id == currentClickedFontID && (
                      <div className={brandFont.thirdRowMenuOptions}>
                        <div
                          className={brandFont.thirdRowMenuText}
                          onClick={(event) =>
                            editClickHandler(font.id, event, font.font_name)
                          }
                        >
                          Edit title
                        </div>
                        <div
                          onClick={(event) =>
                            deleteClickHandler(font.id, event)
                          }
                        >
                          Delete
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className={brandFont.fourthRow} onClick={closeMenuOption}>
        <div className={brandFont.fourthRowHeading}>Customization</div>
        <div className={brandFont.fourthRowHeadingDesc}>
          Manage your preferences
        </div>
      </div>
      <div className={brandFont.fifthRow} onClick={closeMenuOption}>
        <div className={brandFont.fifthRowColumn}>
          <div className={brandFont.fifthDropdownLabel}>Digits</div>
          <div>
            <NewFontSelector
              fontName={digitFont}
              updateInBackend={handleUpdateDefaultFont}
            />
          </div>
        </div>
        <div className={brandFont.fifthRowColumn}>
          <div className={brandFont.fifthDropdownLabel}>Labels</div>
          <div>
            <NewFontSelector
              fontName={labelFont}
              updateInBackend={handleUpdateLabelFont}
            />
          </div>
        </div>
      </div>
      <div className={brandFont.sixthRow} onClick={closeMenuOption}>
        <Checkbox
          defaultChecked={defaultFontStatus}
          className={brandFont.option}
          toggle
          label="Set as default for all subaccounts"
          onChange={changeDefaultStatus}
        />
      </div>
      {renderModal()}
    </>
  );
}
